import { ApplicationsListLayout } from './layouts';
import { ApplicationContextProvider } from './context';
import { LoadingPage } from '~/pages/LoadingPage';
import { ErrorPage, ErrorHandler } from '~/pages/ErrorPage';
import { useWindowDocumentTitle } from '~/lib/useWindowDocumentTitle';
import { useApplicationsPageProps } from './data/useApplicationsPageProps';

export function ApplicationsPage() {
  useWindowDocumentTitle('Applications');
  const { initialProps, isLoadingInitialProps, isInitialPropsError, initialPropsError } = useApplicationsPageProps();

  if (!isInitialPropsError && isLoadingInitialProps) {
    return <LoadingPage />;
  }
  if (isInitialPropsError) {
    return <ErrorHandler error={initialPropsError} />;
  }
  if (!initialProps) {
    return <ErrorPage customMessage="Failed to fetch page props." />;
  }

  return (
    <ApplicationContextProvider initialProps={initialProps}>
      <ApplicationsListLayout />
    </ApplicationContextProvider>
  );
}
