import { HttpError } from '~/lib/useRequest';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { usePatchApplication } from '~/pages/ApplicationDetails/data/useApplication';

export function usePassScreeningActivity() {
  const { apiError } = useErrorHandler();
  const { startProcess, completeProcess, application } = useAppProcessing();
  const { patchApplication } = usePatchApplication(application.id);

  function submitPassScreening() {
    return patchApplication({ status: 'READY_FOR_ASSESSMENT' })
      .catch((err) => {
        if (HttpError.of(err).message === 'invalid status') {
          apiError('Application is already screened, please refresh the page');
        } else {
          apiError();
        }
        throw err;
      })
      .finally(() => completeProcess());
  }

  function passScreening() {
    // check if document has additional documents
    if (application.meta.additionalDocumentRequest?.documents.some((f) => f.status.code === 'PENDING')) {
      startProcess('passScreening');
      return Promise.resolve();
    }

    return submitPassScreening();
  }

  return { passScreening, submitPassScreening };
}
