import { useErrorHandler } from '~/hooks/useErrorHandler';

import { usePatchApplication } from '~/pages/ApplicationDetails/data/useApplication';
import {
  PreOfferLetterPreview,
  usePreOfferLetterPreview,
} from '~/pages/ApplicationDetails/data/usePreOfferLetterPreview';

export type ApplicationProcessingDialog = 'preOffer' | 'decline' | 'none';

export function useIssuePreOfferActivity(applicationId: string) {
  const { apiError } = useErrorHandler();
  const { patchApplication } = usePatchApplication(applicationId);
  const { generatePreOfferLetterPreview } = usePreOfferLetterPreview(applicationId);

  async function generatePreOffer(props: {
    conditions: Array<string>;
    customCondition: string;
    deferralRequestId?: string;
    additionalNotes?: string;
  }): Promise<PreOfferLetterPreview> {
    return await generatePreOfferLetterPreview({
      conditions: props.conditions,
      customCondition: props.customCondition,
      deferralRequestId: props.deferralRequestId,
      additionalNotes: props.additionalNotes,
    }).catch((err) => {
      apiError();
      throw err;
    });
  }

  async function issuePreOffer(
    conditions: Array<string>,
    customCondition: string,
    additionalNotes?: string
  ): Promise<boolean> {
    const response = await patchApplication({
      status: 'INITIAL_OFFER',
      conditions,
      customCondition,
      additionalNotes,
    });

    const hasInitialOfferActions = response.status.actionGroups[0].actions.length > 0;
    return hasInitialOfferActions;
  }

  return {
    generatePreOffer,
    issuePreOffer,
  };
}
