import { Button, Flex, MenuButton } from '@applyboard/crystal-ui';
import { AccountCircleFillIcon, LogoutOutlineIcon } from '@applyboard/ui-icons';
import React from 'react';
import { useAuth } from '~/lib/useAuth';
import { AppBarLogo } from './AppBarLogo';

type AppbarProps = {
  logo: string;
  title: string;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
};

export function AppBar(props: AppbarProps) {
  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const auth = useAuth();
  const logout = React.useCallback(async () => {
    await auth.logout();
  }, [auth]);

  return (
    <nav aria-label="Top navigation bar">
      <Flex direction="row" py={2} p={2} align="center" justify="between">
        <AppBarLogo
          logo={props.logo}
          title={props.title}
          toggleSidebar={props.toggleSidebar}
          isSidebarOpen={props.isSidebarOpen}
        />
        <Flex justify="start">
          <MenuButton open={userMenuOpen} alignment="right" onOpenChange={setUserMenuOpen}>
            <MenuButton.Trigger>
              <Button
                size="md"
                variant="transparent"
                aria-label="Open menu"
                intent="primary"
                leadIcon={AccountCircleFillIcon}
                onClick={() => setUserMenuOpen(!userMenuOpen)}
              />
            </MenuButton.Trigger>
            <MenuButton.Button icon={LogoutOutlineIcon} id="logout_button" onClick={logout}>
              Log out
            </MenuButton.Button>
          </MenuButton>
        </Flex>
      </Flex>
    </nav>
  );
}
