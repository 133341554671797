import { useMutation } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import {
  GetFinalOfferLetterPreviewRequestBody,
  GetFinalOfferLetterPreviewResponse,
} from '@ab/ams-school-api/types/private/v1';

export type FinalOfferLetterPreview = GetFinalOfferLetterPreviewResponse['data']['attributes'];

type GetFinalOfferLetterPreviewRequestPayload = Omit<
  GetFinalOfferLetterPreviewRequestBody['data']['attributes'],
  'type'
>;

export function useFinalOfferLetterPreview(applicationId: string) {
  const request = useRequest();
  const { isLoading, error, mutateAsync } = useMutation(
    ['application_final_offer_letter_preview', applicationId],
    async (payload: GetFinalOfferLetterPreviewRequestPayload) => {
      const response = await request<GetFinalOfferLetterPreviewResponse>(
        `${config.apiHost}/private/v1/applications/${applicationId}/final_offer_letter_preview`,
        {
          method: 'POST',
          body: {
            data: {
              type: 'letter_preview',
              attributes: { ...payload, type: 'FINAL_OFFER' },
            },
          } as GetFinalOfferLetterPreviewRequestBody,
          isExpectedResponse,
        }
      );

      const attributes = response.data.attributes;
      return {
        ...attributes,
      };
    }
  );
  return {
    generateFinalOfferLetterPreview: mutateAsync,
    isGeneratingLetterPreview: isLoading,
    letterPreviewGenerationError: error,
  };
}

function isExpectedResponse(res: unknown): res is GetFinalOfferLetterPreviewResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
