import React from 'react';
import { Dialog, Button, Text, FileList, Flex } from '@applyboard/crystal-ui';
import { UploadFileData } from '../../../types';
import { useDeleteFileFromApplication } from '~/pages/ApplicationDetails/data/useUpdateApplication';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { useNotice } from '../useNotice';
import { useApplicationFileUploadContext } from '~/pages/ApplicationDetails/context/ApplicationFileUploadProvider';

type RemoveDocumentDialogProps = {
  document: Omit<UploadFileData, 'file'>;
  fileId: string;
  onClose: () => void;
};

type RemoveDocumentProps = {
  document: Omit<UploadFileData, 'file'>;
  fileId: string;
};

export function RemoveDocument(props: RemoveDocumentProps) {
  const [open, setOpen] = React.useState(false);
  const { document: file } = props;

  return (
    <>
      <FileList onRemove={() => setOpen(true)} orientation="vertical">
        <FileList.Item fileType="PDF" name={file.fileName} value="0" />
      </FileList>
      {open && <RemoveDocumentDialog fileId={props.fileId} document={props.document} onClose={() => setOpen(false)} />}
    </>
  );
}

export function RemoveDocumentDialog(props: RemoveDocumentDialogProps) {
  const { application } = useAppProcessing();
  const { deleteUploadingFile } = useApplicationFileUploadContext();
  const { deleteFileFromApplicationAsync, isDeletingFile } = useDeleteFileFromApplication({
    id: application.id,
  });
  const { setNotice } = useNotice();
  const { document: file } = props;
  return (
    <Dialog
      size="md"
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) props.onClose();
      }}
    >
      <Dialog.Heading>Remove Document</Dialog.Heading>
      <Dialog.Content>
        <Flex gap={8} direction="column">
          <Text variant="bodyM" contrast="low">
            Are you sure you want to remove this document? Removing the current document will also remove the version
            sent to the student.
          </Text>
          <FileList orientation="vertical">
            <FileList.Item fileType="PDF" name={file.fileName} value="0" onRemove={null} />
          </FileList>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button
          intent="primary"
          width="fill"
          loading={isDeletingFile}
          onClick={async () => {
            await deleteFileFromApplicationAsync(props.fileId).then((response) => {
              if (response) {
                deleteUploadingFile(props.fileId);
                setNotice('Successfully removed a document', 'positive');
              }
            });
          }}
        >
          Remove
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
