import { useToasts } from '@applyboard/crystal-ui';
export const GENERIC_ERROR_MESSAGE = 'Something went wrong, please try again!';
import { useEventTracking } from './useEventTracking';

export function useErrorHandler() {
  const { negative } = useToasts();
  const { trackError } = useEventTracking('API Error');

  function apiError(msg?: string) {
    const error = Error(msg ?? GENERIC_ERROR_MESSAGE);
    negative(error);
    trackError({ event: 'API Error', error });
  }

  return { apiError };
}
