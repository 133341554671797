import { QueryParameterSchema } from './types';

export const intakeTermFilterQueryFilter: QueryParameterSchema<'intakeTermId'> = {
  getDefaultValue: () => [],
  getValue: ({ queryParameters }) => queryParameters.intakeTermId,

  fromUrlSearchParams: ({ urlSearchParams }) => ({
    intakeTermId: (urlSearchParams.get('filter[intakeTermId]') || '').split(','),
  }),
  toUrlSearchParams: ({ value }) => ({ 'filter[intakeTermId]': value.join(',') }),
  toSearchQuery: ({ value }) => {
    if (value.length === 0) return {};
    return { 'filter[intakeTermId]': value };
  },
  sanitize: ({ value, staticProps }) => {
    const {
      referenceData: { intakeTerms },
    } = staticProps;
    return { intakeTermId: value.filter((s) => !!s && !!intakeTerms[s]) };
  },
  getSummaryItems: ({
    value,
    staticProps: {
      referenceData: { intakeTerms },
    },
  }) => {
    return value.map((intakeTerm) => ({
      filterKey: 'intakeTermId',
      key: intakeTerm,
      label: `${intakeTerms[intakeTerm]?.name}`,
      value: intakeTerm,
    }));
  },
  removeParam: ({ value, removedValue }) => {
    return { intakeTermId: value.filter((item) => item !== removedValue) };
  },
};
