import { useQuery } from '@tanstack/react-query';
import { GetApplicationReportsResponse } from '@ab/ams-school-api/types/private/v1';

import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import { IsNullOrUndefined, hasKey } from '~/utils';

export type ReportsQuery = {
  'filter[intakeTermId]': string;
  'filter[programId]': string;
  'filter[campusId]': string;
};

export type Stats = GetApplicationReportsResponse['data']['attributes']['stats'][number];

const defaultValue: Stats[] = [];

export function useReports(query: ReportsQuery, options?: { onSuccess?: (data: Stats[]) => void; enabled: boolean }) {
  const request = useRequest();
  const { isLoading, isFetching, error, data, isPreviousData, isError } = useQuery(
    ['application_reports', query],
    async () => {
      const response = await request<GetApplicationReportsResponse>(
        `${config.apiHost}/private/v1/reports/applications?${convertToUrlSearchParams(query)}`,
        {
          method: 'GET',
          isExpectedResponse,
        }
      );
      const data = response.data;
      const stats = data.attributes.stats;

      // trigger on success
      options?.onSuccess?.(stats);

      return { stats };
    },
    {
      keepPreviousData: true,
      enabled: options?.enabled === false ? false : true,
    }
  );

  return {
    isPreviousStatsData: isPreviousData,
    stats: data ? data.stats : defaultValue,
    statsError: error,
    isStatsError: isError,
    isFetchingStats: isFetching,
    isLoadingStats: isLoading,
  };
}

function isExpectedResponse(res: unknown): res is GetApplicationReportsResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}

const convertToUrlSearchParams = (params: ReportsQuery, baseKey?: string) => {
  const queryParams = {} as Record<string, string>;
  Object.entries(params).forEach(([k, v]) => {
    const key = (baseKey && `${baseKey}[${k}]`) || k;
    if (IsNullOrUndefined(v)) {
      return;
    }

    queryParams[key] = v;
  });
  return new URLSearchParams(queryParams);
};
