import { useMediaQuery } from '~/hooks/useMediaQuery';
import { DesktopLayout } from './DesktopLayout';
import { TabletLayout } from './TabletLayout';
import { MobileLayout } from './MobileLayout';

export const ApplicationsListLayout = () => {
  const { isUltraWide, isWide, isNormal, isNarrow, isUltraNarrow, isMobile } = useMediaQuery();

  if (isUltraWide || isWide) {
    return <DesktopLayout />;
  }

  if (isNormal || isNarrow || isUltraNarrow) {
    return <TabletLayout />;
  }

  if (isMobile) {
    return <MobileLayout />;
  }

  // should not reach here
  return <DesktopLayout />;
};
