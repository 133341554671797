import React, { createContext, useContext, useMemo } from 'react';

import { useSession } from './SessionContext';
import { queryParametersSchema } from '../schema';
import { useStaticProps } from './StaticPropsContext';
import { useQueryParameter } from './QueryParameterContext';
import { useEventTracking } from '../hooks/useEventTracking';
import { Application, useApplications, ApplicationSearchQuery } from './../data/useApplications';

export interface SearchResult {
  applications: Array<Application>;
  totalApplications: number;

  isPreviousApplicationsData: boolean;
  isLoadingApplications: boolean;
  isFetchingApplications: boolean;
  isApplicationsError: boolean;
}

const searchResultContext = createContext<SearchResult | undefined>(undefined);

export const SearchResultContextProvider = ({ children }: { children: React.ReactNode }) => {
  const sessionId = useSession();
  const staticProps = useStaticProps();
  const { trackEvent } = useEventTracking('Search Result');
  const { queryParameters, rnd, queryId } = useQueryParameter();

  const searchQueryParams = useMemo(() => {
    const params = {
      'page[number]': 1,
      'page[size]': 1,
      'filter[q]': '',
      'meta[rnd]': rnd,
      'meta[query_id]': queryId,
      'meta[session_id]': sessionId,
    } as ApplicationSearchQuery;
    queryParametersSchema.parameters.forEach((parameter) => {
      const value = parameter.getValue({ staticProps, queryParameters });
      const defaultValue = parameter.getDefaultValue({ initialQueryParameters: staticProps.initialQueryParameter });
      const result = parameter.toSearchQuery({
        value,
        staticProps,
        defaultValue,
        queryParameters,
      });
      if (!result) return;
      Object.assign(params, result);
    });
    return params;
  }, [staticProps, queryParameters, rnd, queryId, sessionId]);

  const {
    applications,
    totalApplications,
    isLoadingApplications,
    isFetchingApplications,
    isPreviousApplicationsData,
    isApplicationsError,
  } = useApplications(searchQueryParams, {
    onSuccess: (data, total) => {
      trackEvent({
        event: 'Results Returned',
        data: {
          rnd,
          queryId,
          page_total_applications: data.length,
          overall_total_applications: total,
          queryParameters: queryParameters,
        },
      });
    },
  });

  const value = useMemo(
    () => ({
      applications,
      totalApplications,
      isPreviousApplicationsData,
      isLoadingApplications,
      isFetchingApplications,
      isApplicationsError,
    }),
    [
      applications,
      isPreviousApplicationsData,
      totalApplications,
      isLoadingApplications,
      isFetchingApplications,
      isApplicationsError,
    ]
  );

  return <searchResultContext.Provider value={value}>{children}</searchResultContext.Provider>;
};

export const useSearchResult = () => {
  const value = useContext(searchResultContext);
  if (value === undefined) {
    throw new Error('useSearchResult must be used within a SearchResultContextProvider');
  }
  return value;
};
