import { Flex } from '@applyboard/crystal-ui';

import { PageHeader } from '../../../components/PageHeader';
import { ProgramFilter, IntakeTermFilter, CampusesFilter, ApplicationStats } from '../components';

export function TabletLayout() {
  return (
    <Flex p={10} gap={3} direction="column">
      <PageHeader title="Reports" />
      <Flex gap={2}>
        <Filter as={<ProgramFilter />} width="33.333%" />
        <Filter as={<CampusesFilter />} width="33.333%" />
        <Filter as={<IntakeTermFilter />} width="33.333%" />
      </Flex>
      <Flex pt={4}>
        <ApplicationStats />
      </Flex>
    </Flex>
  );
}

function Filter(props: { as: React.ReactNode; width: string }) {
  return <div style={{ width: props.width }}>{props.as}</div>;
}
