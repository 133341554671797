import { Flex } from '@applyboard/crystal-ui';

import { PageHeader } from '../../../components/PageHeader';
import {
  ApplicationsTable,
  QueryFilter,
  ApplicationsPagination,
  FilterSummary,
  StatusFilter,
  NationalityFilter,
  SubmissionDateFilter,
  ProgramFilter,
  IntakeTermFilter,
} from '../components';

export function DesktopLayout() {
  return (
    <Flex p={10} gap={3} direction="column">
      <PageHeader title="Applications" />
      <Flex wrap gap={2}>
        <Filter as={<QueryFilter />} />
        <Filter as={<NationalityFilter />} />
        <Filter as={<ProgramFilter />} />
        <Filter as={<IntakeTermFilter />} />
        <Filter as={<StatusFilter />} />
        <Filter as={<SubmissionDateFilter />} />
      </Flex>
      <Flex pt={2}>
        <FilterSummary />
      </Flex>
      <ApplicationsTable />
      <ApplicationsPagination />
    </Flex>
  );
}

function Filter(props: { as: React.ReactNode }) {
  return <Flex.Item grow={1}>{props.as}</Flex.Item>;
}
