import { QueryParameterSchema } from './types';

export const programIdFilterQueryParameter: QueryParameterSchema<'programId'> = {
  getDefaultValue: () => undefined,
  getValue: ({ queryParameters }) => queryParameters.programId,

  fromUrlSearchParams: ({ urlSearchParams }) => ({
    programId: urlSearchParams.get('filter[programId]') || undefined,
  }),
  toUrlSearchParams: ({ value }) => ({ 'filter[programId]': value }),
  toSearchQuery: ({ value }) => {
    if (!value) return {};
    return { 'filter[programId]': value };
  },
};
