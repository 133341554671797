import { useApplicationFileUploadContext } from '~/pages/ApplicationDetails/context/ApplicationFileUploadProvider';
import { RemoveDocument } from './RemoveDocumentDialog';
import { Flex, Text } from '@applyboard/crystal-ui';
import moment from 'moment';

export function UploadDocumentsList() {
  const { visibleFileState } = useApplicationFileUploadContext();
  return (
    <>
      {Object.keys(visibleFileState).length !== 0 ? (
        <Flex gap={2} direction="row">
          {Object.keys(visibleFileState).map((id) => (
            <Flex gap={2} direction="column" key={id} wrap>
              <Flex.Item key={`${id}-document`}>
                <RemoveDocument key={`${id}-file`} document={visibleFileState[id]} fileId={id} />
              </Flex.Item>
              <Flex.Item key={`${id}-text`}>
                <Text variant="labelS" contrast="mid">
                  Uploaded on {moment(visibleFileState[id].uploadedAt).format('MMM D, YYYY')}
                </Text>
              </Flex.Item>
            </Flex>
          ))}
        </Flex>
      ) : null}
    </>
  );
}
