import React from 'react';

import { AppProcessingProvider } from './ApplicationProcessingProvider';
import { Application } from '~/pages/ApplicationDetails/data/useApplication';
import { ApplicationCardsStateProvider } from './ApplicationCardsStateProvider';
import { ApplicationAdditionalDocumentRequestContextProvider } from './AdditionalDocumentRequestProvider';

export type { CardSection } from './ApplicationCardsStateProvider';
export { useAppProcessing } from './ApplicationProcessingProvider';
export { useAdditionalDocumentRequest } from './AdditionalDocumentRequestProvider';
export { useApplicationCards, useApplicationCard } from './ApplicationCardsStateProvider';

type ApplicationDetailContextProps = {
  application: Application;
  children: React.ReactNode;
};
export const ApplicationDetailContext = (props: ApplicationDetailContextProps) => {
  return (
    <AppProcessingProvider application={props.application}>
      <ApplicationCardsStateProvider>
        <ApplicationAdditionalDocumentRequestContextProvider>
          {props.children}
        </ApplicationAdditionalDocumentRequestContextProvider>
      </ApplicationCardsStateProvider>
    </AppProcessingProvider>
  );
};
