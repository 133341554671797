import React from 'react';
import { Flex, Chip, ChipGroup } from '@applyboard/crystal-ui';
import { useQueryParameter } from './../context';
import { useEventTracking } from './../hooks/useEventTracking';
export const FilterSummary = () => {
  const { trackEvent } = useEventTracking('Filter Summary');
  const { queryParameterSummary, removeQueryParameterValue, resetQueryParameters, rnd, queryId } = useQueryParameter();

  const chips = React.useMemo(() => {
    const result = queryParameterSummary.map((summaryItem) => (
      <Chip
        key={summaryItem.key}
        onDelete={() => {
          removeQueryParameterValue(summaryItem.filterKey, summaryItem.value);
          trackEvent({
            event: 'Clicked Remove Filter Chip',
            data: {
              rnd,
              queryId,
              parameter_key: summaryItem.filterKey,
              parameter_uid: summaryItem.key,
              parameter_value: summaryItem.value,
            },
          });
        }}
      >
        {summaryItem.label}
      </Chip>
    ));
    result.push(
      <ChipGroup.ClearAllButton
        key="clear-all"
        onClick={() => {
          resetQueryParameters();
          trackEvent({
            event: 'Clicked Clear All',
          });
        }}
      >
        Clear all filters
      </ChipGroup.ClearAllButton>
    );
    return result;
  }, [queryParameterSummary, resetQueryParameters, removeQueryParameterValue, trackEvent, rnd, queryId]);
  if (queryParameterSummary.length === 0) return null;
  return (
    <Flex justify="between" gap={1} align="center">
      <ChipGroup size="sm" lines={1} variant="outlined">
        {chips}
      </ChipGroup>
    </Flex>
  );
};
