import React from 'react';

export type CardSection =
  | 'application'
  | 'applicant'
  | 'contactInformation'
  | 'applicationFee'
  | 'agency'
  | 'programIntake'
  | 'statusAndCitizenship'
  | 'languageProficiency'
  | 'educationHistory'
  | 'decisionLetters'
  | 'uploadAdditionalDocuments'
  | 'supportingDocuments';

type Card = {
  id: CardSection;
  isExpanded: boolean;
};

type State = {
  cards: Partial<Record<CardSection, Card>>;
  allExpanded: boolean;
  allCollapsed: boolean;
  toggle: (id: CardSection, expanded: boolean) => void;
  expandAll: () => void;
  collapseAll: () => void;
  register: (id: CardSection) => void;
  unRegister: (id: CardSection) => void;
};
const CardsStateContext = React.createContext<State | undefined>(undefined);

type ApplicationCardsStateProviderProps = {
  expanded?: boolean;
  children: React.ReactNode;
};
export function ApplicationCardsStateProvider(props: ApplicationCardsStateProviderProps) {
  const [cards, setCards] = React.useState<State['cards']>({});

  const expandAll = React.useCallback(
    () =>
      setCards((c) =>
        Object.values(c).reduce((acc, card) => ({ ...acc, [card.id]: { ...card, isExpanded: true } }), {})
      ),
    []
  );
  const collapseAll = React.useCallback(
    () =>
      setCards((c) =>
        Object.values(c).reduce((acc, card) => ({ ...acc, [card.id]: { ...card, isExpanded: false } }), {})
      ),
    []
  );

  const toggle = React.useCallback(
    (id: CardSection, expanded: boolean) => setCards((c) => ({ ...c, [id]: { ...c[id], isExpanded: expanded } })),
    []
  );

  const register = React.useCallback(
    (id: CardSection) => setCards((c) => ({ ...c, [id]: { id, isChanged: false, isExpanded: true } })),
    []
  );
  const unRegister = React.useCallback((id: CardSection) => {
    setCards((c) => {
      const newCards = { ...c };
      delete newCards[id];
      return newCards;
    });
  }, []);

  const allExpanded = Object.values(cards).every((c) => c.isExpanded);
  const allCollapsed = Object.values(cards).every((c) => !c.isExpanded);

  const value = React.useMemo(
    () => ({
      cards,
      toggle,

      allExpanded,
      allCollapsed,

      expandAll,
      collapseAll,

      register,
      unRegister,
    }),
    [cards, toggle, allExpanded, allCollapsed, expandAll, collapseAll, register, unRegister]
  );

  return <CardsStateContext.Provider value={value}>{props.children}</CardsStateContext.Provider>;
}

export function useApplicationCards() {
  const value = React.useContext(CardsStateContext);
  if (!value) throw new Error('useApplicationCards should be used within <ApplicationDetailsCardsStateProvider />');
  return value;
}
export function useApplicationCard(id: CardSection) {
  const { cards, register, unRegister, toggle } = useApplicationCards();
  const card = cards[id];

  React.useEffect(() => {
    register(id);
    return () => unRegister(id);
  }, [id, register, unRegister]);

  return {
    isExpanded: card?.isExpanded || false,
    toggle: (isExpanded: boolean) => toggle(id, isExpanded),
  };
}
