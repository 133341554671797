import { Flex } from '@applyboard/crystal-ui';
import { useFilterField } from '../../hooks/useFilterField';
import { DatePicker } from '~/components/uie-fix/DatePicket';
import { MIN_SUBMISSION_DATE_FILTER_VALUE } from '../../schema/queryParametersSchema/submissionDateFilterSchema';

function formatDate(date: string | number) {
  return new Date(date).toLocaleString('en-CA', {
    timeZoneName: 'shortOffset',
    hour12: false,
  });
}

function calculateLowerBoundDate() {
  return new Date(MIN_SUBMISSION_DATE_FILTER_VALUE).toISOString();
}

function calculateUpperBoundDate(date: string) {
  const endOfDay = new Date(date).getTime() + 86399999;
  return endOfDay <= Date.now() ? endOfDay : Date.now() - 1;
}

type SubmissionDateFilterType = 'from' | 'to';

function getValue(submissionDateFilterType: SubmissionDateFilterType, filterValue: { from?: string; to?: string }) {
  if (submissionDateFilterType === 'from') {
    return filterValue?.from ? new Date(filterValue.from).toISOString() : '';
  } else if (submissionDateFilterType === 'to') {
    return filterValue?.to ? new Date(filterValue.to).toISOString() : '';
  } else throw new Error(`${submissionDateFilterType} is not implemented`);
}

export function SubmissionDateFilter() {
  const { value, setValue: setSubmissionDate } = useFilterField('submissionDate', 'PrimaryFilter');

  return (
    <Flex gap={2}>
      <Flex role="grid" aria-labelledby="startDate">
        <DatePicker
          granularity="day"
          helpText=""
          maxDate={value.to ? new Date(value.to).toISOString() : new Date().toISOString()}
          minDate={calculateLowerBoundDate()}
          onChange={(val) => {
            setSubmissionDate({
              ...value,
              from: val.length ? formatDate(val) : '',
            });
          }}
          size="md"
          value={getValue('from', value)}
        />
      </Flex>
      <Flex role="grid" aria-labelledby="endDate">
        <DatePicker
          granularity="day"
          helpText=""
          maxDate={new Date().toISOString()}
          minDate={value.from ? new Date(value.from).toISOString() : calculateLowerBoundDate()}
          onChange={(val) => {
            setSubmissionDate({
              ...value,
              to: val.length ? formatDate(calculateUpperBoundDate(val)) : '',
            });
          }}
          size="md"
          value={getValue('to', value)}
        />
      </Flex>
    </Flex>
  );
}
