import { useQuery } from '@tanstack/react-query';
import { useRequest } from '~/lib/useRequest';

import { config } from '~/config';

type ReportsPagePropsResponse = {
  referenceData: {
    campuses: Record<string, { name: string }>;
    intakeTerms: Record<string, { name: string; startsOn: string }>;
  };
};

export type ReferenceData = ReportsPagePropsResponse['referenceData'];
export type InitialQueryParameter = {};
export type ReportsPageInitialProps = ReportsPagePropsResponse;

export function useReportsPageProps() {
  const request = useRequest();
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['useReportsPageProps'],
    queryFn: async () => {
      const response = await request<ReportsPagePropsResponse>(`${config.apiHost}/private/v1/reports/page_props`, {
        isExpectedResponse,
      });
      return response as ReportsPageInitialProps;
    },
  });
  return {
    isInitialPropsError: isError,
    initialPropsError: error,
    isLoadingInitialProps: isLoading,
    initialProps: data,
  };
}

function isExpectedResponse(res: unknown): res is ReportsPagePropsResponse {
  return Boolean(res && typeof res === 'object');
}
