import { useErrorHandler } from '~/hooks/useErrorHandler';
import { usePatchApplication } from '~/pages/ApplicationDetails/data/useApplication';
import {
  WaitlistLetterPreview,
  useWaitlistLetterPreview,
} from '~/pages/ApplicationDetails/data/useWaitlistLetterPreview';

export function useIssueWaitlistLetterActivity(applicationId: string) {
  const { apiError } = useErrorHandler();
  const { patchApplication } = usePatchApplication(applicationId);
  const { generateWaitlistLetterPreview } = useWaitlistLetterPreview(applicationId);

  async function generateWaitlistLetter(props: {
    conditions: Array<string>;
    customCondition: string;
    deferralRequestId?: string;
    additionalNotes?: string;
  }): Promise<WaitlistLetterPreview> {
    return await generateWaitlistLetterPreview({
      conditions: props.conditions,
      customCondition: props.customCondition,
      deferralRequestId: props.deferralRequestId,
      additionalNotes: props.additionalNotes,
    }).catch((err) => {
      apiError();
      throw err;
    });
  }

  async function issueWaitlistLetter(
    conditions: Array<string>,
    customCondition: string,
    additionalNotes?: string
  ): Promise<boolean> {
    const response = await patchApplication({
      status: 'WAITLISTED',
      conditions,
      customCondition,
      additionalNotes,
    });

    const hasWaitlistLetterActions = response.status.actionGroups[0].actions.length > 0;
    return hasWaitlistLetterActions;
  }

  return {
    generateWaitlistLetter,
    issueWaitlistLetter,
  };
}
