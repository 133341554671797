import React from 'react';

import { SessionContextProvider } from './SessionContext';
import { StaticPropsContextProvider } from './StaticPropsContext';
import { ReportsResultContextProvider } from './ReportsResultContext';
import { QueryParameterContextProvider } from './QueryParameterContext';
import { ReportsPageInitialProps } from '../data/useReportsPageProps';

interface SearchContextProvider {
  children: React.ReactNode;
  initialProps: ReportsPageInitialProps;
}
export function ReportsContextProvider(props: SearchContextProvider) {
  return (
    <SessionContextProvider>
      <StaticPropsContextProvider initialProps={props.initialProps}>
        <QueryParameterContextProvider>
          <ReportsResultContextProvider>{props.children}</ReportsResultContextProvider>
        </QueryParameterContextProvider>
      </StaticPropsContextProvider>
    </SessionContextProvider>
  );
}
