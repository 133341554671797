import { Flex } from '@applyboard/crystal-ui';

import { PageHeader } from '../../../components/PageHeader';
import { ApplicationsTable, QueryFilter, ApplicationsPagination, FilterSummary } from '../components';
import { FilterDrawer } from './FilterDrawer';

export function MobileLayout() {
  return (
    <Flex p={10} gap={3} direction="column">
      <PageHeader title="Applications" />
      <Flex gap={2}>
        <div style={{ width: `88%` }}>
          <Flex.Item grow={1}>
            <QueryFilter />
          </Flex.Item>
        </div>
        <div style={{ width: '4%' }}>
          <Flex.Item grow={1}>
            <FilterDrawer />
          </Flex.Item>
        </div>
      </Flex>
      <Flex pt={2}>
        <FilterSummary />
      </Flex>
      <Flex shrink={2}>
        <ApplicationsTable />
      </Flex>
      <ApplicationsPagination />
    </Flex>
  );
}
