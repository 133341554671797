import { Combobox } from '@applyboard/crystal-ui';
import { useFilterField } from '../../hooks/useFilterField';
import { useStaticProps } from '../../context';

export const CampusesFilter = () => {
  const campusFilter = useFilterField('campusId', 'PrimaryFilter');
  const {
    referenceData: { campuses },
  } = useStaticProps();

  return (
    <Combobox
      value={campusFilter.value ? campusFilter.value : 'Select Campus'}
      aria-label="Select Campus"
      placeholder="Select Campus"
      size="md"
      onChange={(selectedItem) => campusFilter.setValue(selectedItem)}
    >
      {Object.entries(campuses).map(([id, { name }]) => (
        <Combobox.Option label={name} value={id} key={id} />
      ))}
    </Combobox>
  );
};
