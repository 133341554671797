import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeepStringifyDates } from '@ab/ams-school-backend-utils';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import {
  PatchDeferralRequestBody,
  PatchDeferralRequestResponse,
  DeferralRequest,
} from '@ab/ams-school-api/types/private/v1';

export function usePatchDeferralRequest(id: string) {
  const request = useRequest();
  const queryClient = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(
    ['deferral_request'],
    async (payload: Omit<PatchDeferralRequestBody['data'], 'type'>) => {
      const response = await request<PatchDeferralRequestResponse>(
        `${config.apiHost}/private/v1/applications/${id}/deferral_request`,
        {
          method: 'PATCH',
          body: {
            data: { ...payload, type: 'deferral_request' },
          } as PatchDeferralRequestBody,
          isExpectedResponse,
        }
      );

      const req = response.data;
      const attrs = req.attributes;

      queryClient.invalidateQueries(['application', id]);

      return attrs as DeepStringifyDates<DeferralRequest>;
    }
  );

  return {
    patchDeferralRequest: mutateAsync,
    patchDeferralRequestError: error,
    isPatchingDeferralRequest: isLoading,
  };
}
function isExpectedResponse(res: unknown): res is PatchDeferralRequestResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
