import { QueryParameterSchema } from './types';

export const MIN_SUBMISSION_DATE_FILTER_VALUE = '1/1/2022, 12:00:00 AM';
const FILTER_KEY = 'submissionDate';
const THREE_LETTER_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function formatDate(dateString: string) {
  const date = new Date(dateString);
  return THREE_LETTER_MONTHS[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
}

function isDateStringValid(dateString?: string) {
  if (!dateString?.length) return false;
  const date = Date.parse(dateString);
  const lowerBoundDate = Date.parse(MIN_SUBMISSION_DATE_FILTER_VALUE);
  return !isNaN(date) && date >= lowerBoundDate && date <= Date.now();
}

function isToDateValid(from?: string, to?: string) {
  if ((from && !isDateStringValid(from)) || (to && !isDateStringValid(to))) {
    throw Error(`"from" and "to" date values must be valid, if present: ${from} ${to}`);
  }
  if (from && to) {
    return Date.parse(to) >= Date.parse(from) && Date.parse(to) <= Date.now();
  } else if (to) {
    return Date.parse(to) <= Date.now();
  }
  return false;
}

function generateLabel(to?: string, from?: string) {
  const submissionDate = 'SubmissionDate: ';
  if (to && from) {
    return formatDate(to) === formatDate(from)
      ? submissionDate + `on ${formatDate(to)}`
      : submissionDate + `${formatDate(from)} - ${formatDate(to)}`;
  } else if (to) return submissionDate + `until ${formatDate(to)}`;
  else if (from) return submissionDate + `after ${formatDate(from)}`;
  else throw Error('Either or both "to" and "from" dates must be available to generate a label.');
}

function generateKey(to?: string, from?: string) {
  if (to && from) {
    return `${FILTER_KEY}_${from}_${to}`;
  } else if (to) return `${FILTER_KEY}_${to}`;
  else if (from) return `${FILTER_KEY}_${from}`;
  else throw Error('Either or both "to" and "from" dates must be available to generate a key.');
}

export const submissionDateFilterSchema: QueryParameterSchema<'submissionDate'> = {
  getDefaultValue: () => {
    return {};
  },
  getValue: ({ queryParameters }) => queryParameters.submissionDate,
  fromUrlSearchParams: ({ urlSearchParams }) => ({
    submissionDate: {
      from: urlSearchParams.get('filter[submissionDate][from]') || undefined,
      to: urlSearchParams.get('filter[submissionDate][to]') || undefined,
    },
  }),
  toUrlSearchParams: ({ value }) => {
    const urlSearchParams: Record<string, string> = {};
    if (value.from) urlSearchParams['filter[submissionDate][from]'] = encodeURIComponent(value.from);
    if (value.to) urlSearchParams['filter[submissionDate][to]'] = encodeURIComponent(value.to);
    return urlSearchParams;
  },
  toSearchQuery: ({ value }) => {
    return {
      ['filter[submittedAt][from]']: value.from ? new Date(value.from).toISOString() : undefined,
      ['filter[submittedAt][to]']: value.to ? new Date(value.to).toISOString() : undefined,
    };
  },
  sanitize: ({ value }) => {
    const from = value.from && isDateStringValid(value.from) ? value.from : undefined;
    const to = value.to && isDateStringValid(value.to) ? value.to : undefined;
    return {
      submissionDate: {
        from: from,
        to: to && isToDateValid(from, to) ? to : undefined,
      },
    };
  },
  getSummaryItems: ({ value }) => {
    return value.to || value.from
      ? [
          {
            filterKey: FILTER_KEY,
            key: generateKey(value.from, value.to),
            label: generateLabel(value.to, value.from),
            value: value,
          },
        ]
      : [];
  },
  removeParam: () => {
    return {
      submissionDate: {},
    };
  },
};
