import { Flex } from '@applyboard/crystal-ui';

import { PageHeader } from '../../../components/PageHeader';
import {
  ApplicationsTable,
  QueryFilter,
  ApplicationsPagination,
  FilterSummary,
  StatusFilter,
  NationalityFilter,
  SubmissionDateFilter,
  ProgramFilter,
  IntakeTermFilter,
} from '../components';

export function TabletLayout() {
  return (
    <Flex p={10} gap={3} direction="column">
      <PageHeader title="Applications" />
      <div style={{ width: '67%' }}>
        <QueryFilter />
      </div>
      <Flex.Item grow={1}>
        <Flex gap={2} align="stretch" justify="between">
          <Filter as={<NationalityFilter />} width="16%" />
          <Filter as={<ProgramFilter />} width="16%" />
          <Filter as={<IntakeTermFilter />} width="16%" />
          <Filter as={<StatusFilter />} width="16%" />
          <Filter as={<SubmissionDateFilter />} width="30%" />
        </Flex>
      </Flex.Item>
      <Flex pt={2}>
        <FilterSummary />
      </Flex>
      <ApplicationsTable />
      <ApplicationsPagination />
    </Flex>
  );
}

function Filter(props: { as: React.ReactNode; width: string }) {
  return <div style={{ width: `${props.width}` }}>{props.as}</div>;
}
