import { QueryParameterSchema } from './types';

export const intakeTermFilterQueryParameter: QueryParameterSchema<'intakeTermId'> = {
  getDefaultValue: () => undefined,
  getValue: ({ queryParameters }) => queryParameters.intakeTermId,

  fromUrlSearchParams: ({ urlSearchParams }) => ({
    intakeTermId: urlSearchParams.get('filter[intakeTermId]') || undefined,
  }),
  toUrlSearchParams: ({ value }) => ({ 'filter[intakeTermId]': value }),
  toSearchQuery: ({ value }) => {
    if (!value) return {};
    return { 'filter[intakeTermId]': value };
  },
  sanitize: ({ value, staticProps }) => {
    if (!value) return {};
    const {
      referenceData: { intakeTerms },
    } = staticProps;
    return intakeTerms[value] ? { intakeTermId: value } : {};
  },
};
