import { useMutation } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import {
  GetWaitlistLetterPreviewRequestBody,
  GetWaitlistLetterPreviewResponse,
} from '@ab/ams-school-api/types/private/v1';

export type WaitlistLetterPreview = GetWaitlistLetterPreviewResponse['data']['attributes'];

type GetPreOfferLetterPreviewRequestPayload = Omit<GetWaitlistLetterPreviewRequestBody['data']['attributes'], 'type'>;

export function useWaitlistLetterPreview(applicationId: string) {
  const request = useRequest();
  const { isLoading, error, mutateAsync } = useMutation(
    ['application_waitlist_letter_preview', applicationId],
    async (payload: GetPreOfferLetterPreviewRequestPayload) => {
      const response = await request<GetWaitlistLetterPreviewResponse>(
        `${config.apiHost}/private/v1/applications/${applicationId}/waitlist_letter_preview`,
        {
          method: 'POST',
          body: {
            data: {
              type: 'letter_preview',
              attributes: { ...payload, type: 'WAITLIST' },
            },
          } as GetWaitlistLetterPreviewRequestBody,
          isExpectedResponse,
        }
      );

      const attributes = response.data.attributes;
      return {
        ...attributes,
      };
    }
  );
  return {
    generateWaitlistLetterPreview: mutateAsync,
    isGeneratingLetterPreview: isLoading,
    letterPreviewGenerationError: error,
  };
}

function isExpectedResponse(res: unknown): res is GetWaitlistLetterPreviewResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
