import { Notice } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { useDeferralRequestActivity } from './useDeferralRequestActivity';

export function DeferralRequestNotice() {
  const { application, startProcess } = useAppProcessing();
  const { status } = application;
  const { deferralRequest } = application.meta;
  const errorHandler = useErrorHandler();
  const { isDeferralRequest } = useDeferralRequestActivity();

  if (!isDeferralRequest(status.code)) return null;
  if (!deferralRequest) throw errorHandler.apiError();

  return (
    <Notice intent="warning" width="fill">
      This application has a deferral request pending review
      <Notice.Button
        onClick={() => {
          startProcess('deferralRequest');
        }}
      >
        View request
      </Notice.Button>
    </Notice>
  );
}
