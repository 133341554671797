import { Flex, Heading } from '@applyboard/crystal-ui';

type PageHeaderProps = {
  title: string;
  children?: React.ReactNode;
};
export function PageHeader(props: PageHeaderProps) {
  return (
    <Flex pb={5} gap={5} align="center">
      <Heading align="start" contrast="high" intent="secondary" level={1} variant="displayS">
        {props.title}
      </Heading>
      {props.children}
    </Flex>
  );
}
