/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import { UpdateFileMetaData, UpdateApplicationRequest } from 'applications-types-lib';
import { config } from '~/config';
import { ApplicationResource } from '@ab/ams-school-api/types/private/v1';
import type { UploadFileData } from '../types';
import { useRequest } from '~/lib/useRequest';
import { useS3Upload } from './useS3Upload';

type UpdateApplicationProps = {
  id: string;
};

type UpdateApplicationAttributes = UpdateApplicationRequest['data']['attributes'];

type UpdateApplicationParams = {
  attributes?: UpdateApplicationAttributes;
  files?: Record<string, UploadFileData | null>;
};
export type ApplicationMeta = NonNullable<ApplicationResource['meta']>;
export type Application = ApplicationResource['attributes'] & { id: string; meta: ApplicationMeta };
export type ApplicationStatus = Application['status'];
export type Applicant = Application['applicant'];

function stripFileFromData(fileData?: Record<string, UploadFileData | null>) {
  if (!fileData || !Object.keys(fileData).length) return null;

  const strippedFileData: Record<string, UpdateFileMetaData | null> = {};

  Object.keys(fileData).forEach((fileId) => {
    if (fileData[fileId] === null) {
      strippedFileData[fileId] = null;
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { file, uploadedAt, ...rest } = fileData[fileId] as UploadFileData;
    strippedFileData[fileId] = rest;
  });

  return strippedFileData;
}

async function UpdateApplication({
  id,
  attributesValues,
  request,
}: {
  id: string;
  attributesValues: UpdateApplicationAttributes;
  request: ReturnType<typeof useRequest>;
}): Promise<any> {
  try {
    const files = attributesValues.files;
    const response = await request<any>(`${config.apiHost}/private/v1/applications/${id}/additional_attachments`, {
      method: 'POST',
      body: {
        data: {
          id: id,
          type: 'application',
          attributes: {
            files: files,
          },
        },
      },
      isExpectedResponse: function (res: any): res is any {
        return res;
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error during patch:', error);
    throw error;
  }
}
export function useUpdateApplication(props: UpdateApplicationProps) {
  const { s3UploadAsync } = useS3Upload();
  const request = useRequest();
  const { isLoading, mutate, mutateAsync } = useMutation(async (params: UpdateApplicationParams) => {
    const { ...stripped } = params.attributes || {};
    const fileData = stripFileFromData(params.files);
    const attributes: UpdateApplicationAttributes = {
      ...stripped,
      ...(fileData ? { files: fileData } : {}),
    };

    const response = await UpdateApplication({ id: props.id, attributesValues: attributes, request });
    if (params.files && fileData && 'meta' in response.data && 'files' in response.data.meta) {
      const fileParams = params.files as Record<string, UploadFileData | null>;
      const fileMeta = response.data.meta.files;

      const filesToUpload = Object.keys(fileParams).filter((fileId) => {
        return fileParams[fileId] !== null && fileMeta[fileId]?.upload;
      });

      const failedUploads: Record<string, null> = {};
      const s3Uploads = filesToUpload.map(async (fileId) => {
        const upload = fileMeta[fileId].upload;
        if (upload?.url && upload?.fields) {
          try {
            return await s3UploadAsync({
              file: fileParams[fileId]?.file as File,
              url: upload.url,
              fields: upload.fields,
            });
          } catch (error) {
            failedUploads[fileId] = null;
            throw new Error(`Failed to upload file - ${error}`);
          }
        }

        failedUploads[fileId] = null;
        return Promise.resolve();
      });

      await Promise.allSettled(s3Uploads);

      if (Object.keys(failedUploads).length) {
        const filesToUndo: Record<string, UploadFileData | null> = {};
        Object.keys(fileParams).forEach((id) => {
          if (fileParams[id] !== null) filesToUndo[id] = null;
        });
        await UpdateApplication({ id: props.id, attributesValues: { files: filesToUndo }, request });
      }
    }
    return response;
  });

  return {
    isUpdatingApplication: isLoading,
    updateApplication: mutate,
    updateApplicationAsync: mutateAsync,
  };
}

export function useDeleteFileFromApplication(props: UpdateApplicationProps) {
  const request = useRequest();
  const { isLoading, mutate, mutateAsync } = useMutation(async (fileId: string) => {
    const fileData = { [fileId]: null };
    const attributes: UpdateApplicationAttributes = {
      files: fileData,
    };

    const response = await UpdateApplication({ id: props.id, attributesValues: attributes, request });
    return response;
  });

  return {
    isDeletingFile: isLoading,
    deleteFileFromApplication: mutate,
    deleteFileFromApplicationAsync: mutateAsync,
  };
}
