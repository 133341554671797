import { useQuery } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import {
  ApplicationWaitlistConditionResource,
  ListApplicationWaitlistConditionsResponse,
} from '@ab/ams-school-api/types/private/v1';

export type ApplicationWaitlistCondition = ApplicationWaitlistConditionResource['attributes'] & { id: string };

export function useApplicationWaitlistCondition() {
  const request = useRequest();

  const { isLoading, isFetching, error, data, isError } = useQuery({
    queryKey: ['application_waitlist_conditions'],
    queryFn: async () => {
      const response = await request<ListApplicationWaitlistConditionsResponse>(
        `${config.apiHost}/private/v1/waitlist_conditions?page[number]=1&page[size]=100`,
        {
          method: 'GET',
          isExpectedResponse,
        }
      );

      const conditions = response.data;
      return conditions.map((condition) => {
        return {
          id: condition.id,
          name: condition.attributes.name,
          code: condition.attributes.code,
        };
      });
    },
  });
  return {
    isLoadingWaitlistLetterCondition: isLoading,
    isFetchingWaitlistLetterCondition: isFetching,
    waitlistLetterConditionError: error,
    waitlistLetterConditions: data,
    isWaitlistLetterConditionError: isError,
  };
}

function isExpectedResponse(res: unknown): res is ListApplicationWaitlistConditionsResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
