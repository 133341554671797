import { useErrorHandler } from '~/hooks/useErrorHandler';

import { usePatchApplication } from '~/pages/ApplicationDetails/data/useApplication';
import {
  FinalOfferLetterPreview,
  useFinalOfferLetterPreview,
} from '~/pages/ApplicationDetails/data/useFinalOfferLetterPreview';

export function useIssueFinalOfferActivity(applicationId: string) {
  const { apiError } = useErrorHandler();
  const { patchApplication } = usePatchApplication(applicationId);
  const { generateFinalOfferLetterPreview } = useFinalOfferLetterPreview(applicationId);

  async function generateFinalOffer(props: {
    conditions: Array<string>;
    customCondition: string;
    deferralRequestId?: string;
    additionalNotes?: string;
  }): Promise<FinalOfferLetterPreview> {
    return await generateFinalOfferLetterPreview({
      conditions: props.conditions,
      customCondition: props.customCondition,
      deferralRequestId: props.deferralRequestId,
      additionalNotes: props.additionalNotes,
    }).catch((err) => {
      apiError();
      throw err;
    });
  }

  async function issueFinalOffer(
    conditions: Array<string>,
    customCondition: string,
    additionalNotes?: string
  ): Promise<boolean> {
    const response = await patchApplication({
      status: 'FINAL_OFFER_ISSUED',
      conditions,
      customCondition,
      additionalNotes,
    });

    const hasFinalOfferActions = response.status.actionGroups[0].actions.length > 0;
    return hasFinalOfferActions;
  }

  return {
    generateFinalOffer,
    issueFinalOffer,
  };
}
