import { useQuery } from '@tanstack/react-query';
import { ListProgramsResponse, ProgramResource } from '@ab/ams-school-api/types/private/v1';

import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import { IsNullOrUndefined, hasKey } from '~/utils';

export type ProgramsSearchQuery = {
  'filter[q]'?: string;
  'filter[ids]'?: string[];
  'page[size]': number;
  'page[number]': number;
};

export type Program = ProgramResource['attributes'] & { id: string };

const defaultValue: Program[] = [];

export function usePrograms(
  query: ProgramsSearchQuery,
  options?: { onSuccess?: (data: Program[], total: number) => void; keepPreviousData?: boolean; enabled?: boolean }
) {
  const request = useRequest();
  const { isLoading, isFetching, error, data, isPreviousData } = useQuery(
    ['programs', query],
    async () => {
      const response = await request<ListProgramsResponse>(
        `${config.apiHost}/private/v1/programs?${convertToUrlSearchParams(query)}`,
        {
          method: 'GET',
          isExpectedResponse,
        }
      );
      const data = response.data;
      const programs = data.map((app) => {
        const attrs = app.attributes;
        return {
          ...attrs,
          id: app.id,
        } as Program;
      });

      // trigger on success
      options?.onSuccess?.(programs, response.meta.pagination.total);

      return {
        // as we are using keepPreviousData, we need to know the result is based on which page
        pagination: {
          pageNumber: response.meta.pagination.pageNumber,
          pageSize: response.meta.pagination.pageSize,
        },
        programs,
        totalPrograms: response.meta.pagination.total,
      };
    },
    {
      enabled: options?.enabled === undefined ? true : options.enabled,
      keepPreviousData: options?.keepPreviousData === undefined ? true : options?.keepPreviousData,
    }
  );

  return {
    isPreviousProgramsData: isPreviousData,
    programsPagination: data?.pagination,
    programs: data?.programs || defaultValue,
    programsError: error,
    isFetchingPrograms: isFetching,
    isLoadingPrograms: isLoading,
    totalPrograms: data?.programs ?? 0,
  };
}

function isExpectedResponse(res: unknown): res is ListProgramsResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res) && Array.isArray(res.data));
}

const convertToUrlSearchParams = (params: ProgramsSearchQuery, baseKey?: string) => {
  const queryParams = {} as Record<string, string>;
  Object.entries(params).forEach(([k, v]) => {
    const key = (baseKey && `${baseKey}[${k}]`) || k;
    if (IsNullOrUndefined(v)) {
      return;
    }
    if (typeof v === 'string' && v.length > 0) {
      queryParams[key] = v;
    } else if (typeof v === 'number') {
      queryParams[key] = `${v}`;
    } else if (typeof v === 'boolean' && v) {
      queryParams[key] = 't';
    } else if (Array.isArray(v) && v.length > 0) {
      queryParams[key] = v.join(',');
    }
  });
  return new URLSearchParams(queryParams);
};
