import { useMutation } from '@tanstack/react-query';

export interface S3FileUploadParams {
  file: File;
  url: string;
  fields: Record<string, string>;
}

export function useS3Upload() {
  const { isLoading: isPending, mutateAsync } = useMutation({
    mutationFn: (params: S3FileUploadParams) => {
      const formData = new FormData();
      Object.keys(params.fields).forEach((key: string) => formData.append(key, params.fields[key]));
      formData.append('file', params.file);
      return fetch(params.url, {
        method: 'POST',
        body: formData,
      });
    },
    retry: 3,
  });

  return {
    isLoadingS3Upload: isPending,
    s3UploadAsync: mutateAsync,
  };
}
