import { useMediaQuery as baseUseMediaQuery } from 'react-responsive';

export function useMediaQuery() {
  const isUltraWide = baseUseMediaQuery({ minWidth: 1512 });
  const isWide = baseUseMediaQuery({ minWidth: 1440 });
  const isNormal = baseUseMediaQuery({ minWidth: 1366 });
  const isNarrow = baseUseMediaQuery({ minWidth: 1280 });
  const isUltraNarrow = baseUseMediaQuery({ minWidth: 1024 });
  const isMobile = baseUseMediaQuery({ maxWidth: 1024 });

  return { isUltraWide, isWide, isNormal, isNarrow, isUltraNarrow, isMobile };
}
