import { Box, Flex, Text } from '@applyboard/crystal-ui';
import { DocumentCheckOutlineIcon } from '@applyboard/ui-icons';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { formatNonTimezoneDate } from '~/utils/formatDate';
import { Attachment } from '~/pages/ApplicationDetails/data/useApplication';
import { TruncatedButtonLink } from '~/components/uie-fix/TruncatedButtonLink';
import { useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';

import { SingleDocumentRequest } from '../../ApplicationProcessing/AdditionalDocumentRequest/SingleDocumentRequest';

type AttachedDocumentProps = { attachmentDocument: Attachment; disableAdditionalRequest?: boolean };
export function AttachedDocument(props: AttachedDocumentProps) {
  const { isMobile } = useMediaQuery();
  const { attachmentDocument } = props;
  const additionalDocumentRequest = useAdditionalDocumentRequest();

  const hasHistory = attachmentDocument.histories.length > 0;

  return (
    <Flex key={`doc-${attachmentDocument.fileName}`} gap={1} direction="column">
      <Flex gap={isMobile ? 1 : 6} direction={isMobile ? 'column' : 'row'}>
        <Flex direction="column" grow={1}>
          <TruncatedButtonLink
            aria-label={`Download ${attachmentDocument.fileName} document`}
            emphasis="highlighted"
            href={attachmentDocument.downloadUrl!}
            intent={attachmentDocument.validationStatus === 'VALID' ? 'positive' : 'primary'}
            size="lg"
            leadIcon={DocumentCheckOutlineIcon}
            width="fill"
            mode="override_user_choice_and_open_in_new_tab"
          >
            {attachmentDocument.fileName}
          </TruncatedButtonLink>

          {!isMobile && hasHistory && attachmentDocument.updatedAt && (
            <AttachmentUpdatedAt updatedAt={attachmentDocument.updatedAt} />
          )}
        </Flex>
        {additionalDocumentRequest.isEnabled && !props.disableAdditionalRequest && (
          <SingleDocumentRequest
            mode="UPDATE"
            documentTypeCode={attachmentDocument.type.code}
            documentTypeName={attachmentDocument.type.name}
            sectionReference={attachmentDocument.sectionReference}
            notice="Please note that you are requesting an updated proof from the applicant for this document."
          />
        )}
      </Flex>
    </Flex>
  );
}

function AttachmentUpdatedAt(props: { updatedAt: string }) {
  return (
    <Box pl={4}>
      <Text variant="labelS" intent="secondary">{`Updated on ${formatNonTimezoneDate(
        new Date(props.updatedAt)
      )}`}</Text>
    </Box>
  );
}
