import { formatNonTimezoneDate } from '~/utils';
import { BookFillIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { useAppProcessing, useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';
import { Divider, Flex, Text, GridAlpha } from '@applyboard/crystal-ui';
import { SingleDocumentRequest } from '~/pages/ApplicationDetails/components/ApplicationProcessing/AdditionalDocumentRequest/SingleDocumentRequest';
import { TestType, HasLanguageProficiencyCodes } from '@ab/ams-school-api/types/private/v1';

import { Card } from './Card';
import { AttachedDocuments } from './shared/AttachedDocuments';

export function LanguageProficiency() {
  const {
    application: { languageProficiency },
  } = useAppProcessing();
  const additionalDocumentRequest = useAdditionalDocumentRequest();

  return (
    <Card id="languageProficiency" icon={BookFillIcon} title="Language Proficiency">
      {languageProficiency.status === HasLanguageProficiencyCodes.DONT_NEED && (
        <Flex gap={4} direction="column">
          <Text variant="bodyM" contrast="mid">
            N/A
          </Text>
          {additionalDocumentRequest.isEnabled && (
            <SingleDocumentRequest
              documentTypeCode="LANGUAGE_TEST"
              documentTypeName="Language Proficiency Proof"
              mode="ADD"
              sectionReference="LANGUAGE_TEST"
            />
          )}
        </Flex>
      )}
      {languageProficiency.status === HasLanguageProficiencyCodes.WILL_HAVE && (
        <Flex gap={4} direction="column">
          <Text variant="bodyM" contrast="mid">
            To be provided.
          </Text>
          {additionalDocumentRequest.isEnabled && (
            <SingleDocumentRequest
              documentTypeCode="LANGUAGE_TEST"
              documentTypeName="Language Proficiency Proof"
              mode="ADD"
              sectionReference="LANGUAGE_TEST"
            />
          )}
        </Flex>
      )}
      {languageProficiency.status === HasLanguageProficiencyCodes.HAVE &&
        languageProficiency.languageProficiencyData.testType.code === TestType.OTHER && (
          <Flex gap={6} direction="column">
            <Flex gap={10}>
              <LabelValue label="Proof Type" value={languageProficiency.languageProficiencyData.testType.title} />
              <Flex.Item>
                <LabelValue
                  label={languageProficiency.languageProficiencyData.description?.title as string}
                  value={languageProficiency.languageProficiencyData.description?.value}
                />
              </Flex.Item>
            </Flex>
            <AttachedDocuments
              attachmentDocuments={languageProficiency.languageProficiencyData.documents}
              section="languageProficiency"
              sectionReference={languageProficiency.languageProficiencyData.key}
              noRequestForMissingDocuments
            />
          </Flex>
        )}
      {languageProficiency.status === HasLanguageProficiencyCodes.HAVE &&
        languageProficiency.languageProficiencyData.testType.code !== TestType.OTHER && (
          <Flex gap={6} direction="column">
            <LabelValue
              label="Test type"
              value={languageProficiency.languageProficiencyData.testType.title}
              variant="titleS"
            />
            <Flex gap={10}>
              <LabelValue
                label="Test Date"
                value={formatNonTimezoneDate(new Date(languageProficiency.languageProficiencyData.testDate as string))}
              />
              <Flex.Item>
                <LabelValue
                  label={languageProficiency.languageProficiencyData.certificateNumber?.title as string}
                  value={languageProficiency.languageProficiencyData.certificateNumber?.value}
                />
              </Flex.Item>
            </Flex>
            <Divider />
            <LabelValue
              label={languageProficiency.languageProficiencyData.overallScore?.title as string}
              value={languageProficiency.languageProficiencyData.overallScore?.value as string}
              variant="labelL"
              color="primary"
            />
            <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(5, 1fr)">
              <>
                {languageProficiency.languageProficiencyData.subscores?.map((s) => (
                  <LabelValue key={s.title} label={s.title} value={s.formattedValue} variant="titleS" />
                ))}
              </>
              <GridAlpha.Item rowStart={2} rowEnd={2} columnStart={1} columnEnd={5}>
                <AttachedDocuments
                  attachmentDocuments={languageProficiency.languageProficiencyData.documents}
                  section="languageProficiency"
                  sectionReference={languageProficiency.languageProficiencyData.key}
                  noRequestForMissingDocuments
                />
              </GridAlpha.Item>
            </GridAlpha>
          </Flex>
        )}
    </Card>
  );
}
