import { useQuery } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';

import {
  ApplicationFinalOfferConditionResource,
  ListApplicationFinalOfferConditionsResponse,
} from '@ab/ams-school-api/types/private/v1';

export type ApplicationFinalOfferCondition = ApplicationFinalOfferConditionResource['attributes'] & { id: string };

export function useApplicationFinalOfferCondition() {
  const request = useRequest();

  const { isLoading, isFetching, error, data, isError } = useQuery({
    queryKey: ['application_final_offer_conditions'],
    queryFn: async () => {
      const response = await request<ListApplicationFinalOfferConditionsResponse>(
        `${config.apiHost}/private/v1/final_offer_conditions?page[number]=1&page[size]=100`,
        {
          method: 'GET',
          isExpectedResponse,
        }
      );

      const conditions = response.data;
      return conditions.map((condition) => {
        return {
          id: condition.id,
          name: condition.attributes.name,
          code: condition.attributes.code,
        };
      });
    },
  });
  return {
    isLoadingFinalOfferCondition: isLoading,
    isFetchingFinalOfferCondition: isFetching,
    finalOfferConditionError: error,
    finalOfferConditions: data,
    isFinalOfferConditionError: isError,
  };
}

function isExpectedResponse(res: unknown): res is ListApplicationFinalOfferConditionsResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
