import { useMutation, useQueryClient } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import {
  PostAdditionalDocumentRequestBody,
  PostAdditionalDocumentRequestResponse,
  AdditionalDocumentsRequest,
} from '@ab/ams-school-api/types/private/v1';

export function usePostAdditionalDocumentRequest(id: string) {
  const request = useRequest();
  const queryClient = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(
    ['application_additional_document_request'],
    async (payload: PostAdditionalDocumentRequestBody['data']['attributes']) => {
      const response = await request<PostAdditionalDocumentRequestResponse>(
        `${config.apiHost}/private/v1/applications/${id}/additional_document_requests`,
        {
          method: 'POST',
          body: {
            data: {
              type: 'application_additional_document_request',
              attributes: payload,
            },
          } as PostAdditionalDocumentRequestBody,
          isExpectedResponse,
        }
      );

      const req = response.data;
      const attrs = req.attributes;

      queryClient.invalidateQueries(['application', id]);

      return {
        ...attrs,
        requestedAt: new Date(attrs.requestedAt),
        id: req.id,
      } as AdditionalDocumentsRequest;
    }
  );

  return {
    postAdditionalDocumentRequest: mutateAsync,
    postAdditionalDocumentRequestError: error,
    isPostingAdditionalDocumentRequest: isLoading,
  };
}
function isExpectedResponse(res: unknown): res is PostAdditionalDocumentRequestResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
