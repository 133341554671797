import { ReportsListLayout } from './layouts';
import { ReportsContextProvider } from './context';
import { LoadingPage } from '~/pages/LoadingPage';
import { ErrorPage, ErrorHandler } from '~/pages/ErrorPage';
import { useWindowDocumentTitle } from '~/lib/useWindowDocumentTitle';
import { useReportsPageProps } from './data/useReportsPageProps';

export function ReportsPage() {
  useWindowDocumentTitle('Reports');
  const { initialProps, isLoadingInitialProps, isInitialPropsError, initialPropsError } = useReportsPageProps();

  if (!isInitialPropsError && isLoadingInitialProps) {
    return <LoadingPage />;
  }
  if (isInitialPropsError) {
    return <ErrorHandler error={initialPropsError} />;
  }
  if (!initialProps) {
    return <ErrorPage customMessage="Failed to fetch page props." />;
  }

  return (
    <ReportsContextProvider initialProps={initialProps}>
      <ReportsListLayout />
    </ReportsContextProvider>
  );
}
