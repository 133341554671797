import React from 'react';

import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsExporting from 'highcharts/modules/exporting';
import { ReportsResult } from '../../context/ReportsResultContext';

highchartsAccessibility(Highcharts);
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);

type BarChartProps = {
  stats: ReportsResult['stats'];
};
export function BarChart(props: BarChartProps) {
  const series = React.useMemo(
    () => [
      {
        type: 'column' as const,
        name: 'Applications',
        colorByPoint: true,
        data: props.stats.map((stat) => ({
          y: stat.value,
          name: stat.title,
          color: stat.color,
        })),
      },
    ],
    [props.stats]
  );

  const ordered = React.useMemo(() => structuredClone(props.stats).sort((a, b) => b.value - a.value), [props.stats]);
  const yTickInterval = ordered.length > 0 ? Math.ceil(ordered[0].value / 9) : undefined;

  const options: Options = {
    plotOptions: {
      series: {
        animation: false,
      },
    },
    chart: { type: 'bar', animation: false },
    credits: { enabled: false },
    title: { text: '' },
    accessibility: { announceNewData: { enabled: true } },
    xAxis: {
      type: 'category',
      gridLineWidth: 0,
      title: {},
      labels: {
        style: { color: '#626E93', fontSize: '12px', fontFamily: 'Open Sans', fontWeight: '600' },
      },
    },
    yAxis: {
      max: ordered[0]?.value,
      tickInterval: yTickInterval,
      title: { text: '' },
      gridLineWidth: 1,
      tickColor: 'red',
      labels: {
        style: { color: '#626E93', fontFamily: 'Open Sans', fontWeight: '600' },
      },
    },
    legend: { enabled: false },
    exporting: { enabled: false },
    series: series,
  };

  return <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>;
}
