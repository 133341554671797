import { QueryParameterSchema } from './types';

export const campusFilterQueryParameter: QueryParameterSchema<'campusId'> = {
  getDefaultValue: () => undefined,
  getValue: ({ queryParameters }) => queryParameters.campusId,

  fromUrlSearchParams: ({ urlSearchParams }) => ({
    campusId: urlSearchParams.get('filter[campusId]') || undefined,
  }),
  toUrlSearchParams: ({ value }) => ({ 'filter[campusId]': value }),
  toSearchQuery: ({ value }) => {
    if (!value) return {};
    return { 'filter[campusId]': value };
  },
  sanitize: ({ value, staticProps }) => {
    if (!value) return {};
    const {
      referenceData: { campuses },
    } = staticProps;
    return campuses[value] ? { campusId: value } : {};
  },
};
