import React from 'react';
import { Notice, Flex } from '@applyboard/crystal-ui';
import { Intent } from '@applyboard/crystal-ui/lib/types/design';

type Notice = {
  noticeMessage: string;
  intent: Exclude<Intent, 'magic'>;
};

const context = React.createContext<
  [Notice | undefined, React.Dispatch<React.SetStateAction<Notice | undefined>>] | undefined
>(undefined);

export function NoticeContextProvider(props: { children: React.ReactNode }) {
  const state = React.useState<Notice | undefined>(undefined);
  const [noticeState, setNoticeState] = state;
  return (
    <context.Provider value={state}>
      <Flex gap={1}>
        {noticeState && (
          <Notice intent={noticeState.intent} width="fill">
            <Notice.CloseButton onClick={() => setNoticeState(undefined)} />
            {noticeState.noticeMessage}
          </Notice>
        )}
        {!noticeState && props.children}
      </Flex>
    </context.Provider>
  );
}

export function useNotice() {
  const state = React.useContext(context);
  if (state == undefined) {
    throw new Error('useNotice should be used within a NoticeContextProvider');
  }
  const [noticeState, setNoticeState] = state;
  function setNotice(noticeMessage: string, intent: Exclude<Intent, 'magic'>) {
    setNoticeState({ noticeMessage, intent });
  }
  function dismissNotice() {
    setNoticeState(undefined);
  }

  return {
    setNotice,
    dismissNotice,
    notice: noticeState,
  };
}
