import React from 'react';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { usePatchApplication } from '~/pages/ApplicationDetails/data/useApplication';
import { useRejectionLetterPreview } from '~/pages/ApplicationDetails/data/useRejectionLetterPreview';

export function useDeclineActivity() {
  const process = useAppProcessing();

  const { patchApplication } = usePatchApplication(process.application.id);
  const { generateRejectionLetterPreview } = useRejectionLetterPreview(process.application.id);
  const [isBusy, setIsBusy] = React.useState(false);

  function generateRejection(rejectionReason: string) {
    setIsBusy(true);
    return generateRejectionLetterPreview({ reason: rejectionReason })
      .then((result) => result)
      .finally(() => setIsBusy(false));
  }
  async function declineApplicant(reason: string) {
    setIsBusy(true);
    return patchApplication({ status: 'DECLINED', rejectionReason: reason }).finally(() => setIsBusy(false));
  }

  return {
    isBusy,
    generateRejection,
    declineApplicant,
    completeProcess: () => process.completeProcess(),
  };
}
