import { useMutation } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';
import {
  GetPreOfferLetterPreviewResponse,
  GetPreOfferLetterPreviewRequestBody,
} from '@ab/ams-school-api/types/private/v1';

export type PreOfferLetterPreview = GetPreOfferLetterPreviewResponse['data']['attributes'];

type GetPreOfferLetterPreviewRequestPayload = Omit<GetPreOfferLetterPreviewRequestBody['data']['attributes'], 'type'>;

export function usePreOfferLetterPreview(applicationId: string) {
  const request = useRequest();
  const { isLoading, error, mutateAsync } = useMutation(
    ['application_pre_offer_letter_preview', applicationId],
    async (payload: GetPreOfferLetterPreviewRequestPayload) => {
      const response = await request<GetPreOfferLetterPreviewResponse>(
        `${config.apiHost}/private/v1/applications/${applicationId}/pre_offer_letter_preview`,
        {
          method: 'POST',
          body: {
            data: {
              type: 'letter_preview',
              attributes: { ...payload, type: 'PRE_OFFER' },
            },
          } as GetPreOfferLetterPreviewRequestBody,
          isExpectedResponse,
        }
      );

      const attributes = response.data.attributes;
      return {
        ...attributes,
      };
    }
  );
  return {
    generatePreOfferLetterPreview: mutateAsync,
    isGeneratingLetterPreview: isLoading,
    letterPreviewGenerationError: error,
  };
}

function isExpectedResponse(res: unknown): res is GetPreOfferLetterPreviewResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
