import { useSession } from './../context';
import { useEventTracking as useGenericEventTracking } from '../../../hooks/useEventTracking';

type Group = 'Filter Parameters' | 'Applications Report Result';

export const useEventTracking = (group: Group) => {
  const sessionId = useSession();

  return useGenericEventTracking(group, sessionId);
};
