import { ButtonLinkProps, Flex } from '@applyboard/crystal-ui';
import { useMatches } from '../../Routes';

import { SidebarLinkButton } from '../uie-fix/SidebarLinkButton';

type SidebarProps = {
  children: React.ReactNode;
};

export function Sidebar(props: SidebarProps) {
  return (
    <nav aria-label="Side navigation bar">
      <Flex p={2} gap={1} direction="column">
        {props.children}
      </Flex>
    </nav>
  );
}

type SidebarItemProps = {
  title: string;
  href: string;
  icon: ButtonLinkProps['trailIcon'];
  isSidebarOpen: boolean;
};

Sidebar.Item = function AppSidebarItem(props: SidebarItemProps) {
  const matches = useMatches();
  const isSelected = matches.map((s) => s.path).includes(props.href);

  return (
    <SidebarLinkButton
      href={props.href}
      width="fill"
      leadIcon={props.icon}
      intent={isSelected ? 'primary' : 'secondary'}
      aria-label={props.title}
      emphasis={isSelected ? 'highlighted' : 'transparent'}
    >
      {props.isSidebarOpen ? props.title : ''}
    </SidebarLinkButton>
  );
};
