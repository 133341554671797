import React from 'react';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { useAppProcessing } from './ApplicationProcessingProvider';
import { useApplicationCards } from './ApplicationCardsStateProvider';
import { AttachmentTypeCode } from '~/pages/ApplicationDetails/data/useApplication';
import { usePostAdditionalDocumentRequest } from '~/pages/ApplicationDetails/data/useAdditionalDocumentRequest';

type ApplicationAdditionalDocumentRequestDocument = {
  comment: string;
  sectionReference: string;
  type: AttachmentTypeCode;
};

export type ApplicationAdditionalDocumentRequestState = {
  documents: Array<ApplicationAdditionalDocumentRequestDocument>;

  isEnabled: boolean;
  enableFeature: () => void;
  disableFeature: () => void;

  submit: () => Promise<void>;
  addUpdateDocuments: (newDocuments: Array<ApplicationAdditionalDocumentRequestDocument>) => void;
  removeDocuments: (oldDocuments: Array<ApplicationAdditionalDocumentRequestDocument>) => void;
  setDocuments: (newDocuments: Array<ApplicationAdditionalDocumentRequestDocument>) => void;
};

const applicationAdditionalDocumentRequestContext = React.createContext<
  ApplicationAdditionalDocumentRequestState | undefined
>(undefined);

export interface ApplicationAdditionalDocumentRequestContextProps {
  children: React.ReactNode;
}

export function ApplicationAdditionalDocumentRequestContextProvider(
  props: ApplicationAdditionalDocumentRequestContextProps
) {
  const errorHandler = useErrorHandler();
  const process = useAppProcessing();
  const { expandAll } = useApplicationCards();
  const { postAdditionalDocumentRequest } = usePostAdditionalDocumentRequest(process.application.id);

  const [documents, setDocuments] = React.useState<Array<ApplicationAdditionalDocumentRequestDocument>>([]);

  const value = React.useMemo(
    () => ({
      documents: documents,
      isEnabled: process.activeProcess === 'additionalDocumentRequest',
      enableFeature: () => {
        expandAll();
        process.startProcess('additionalDocumentRequest');
      },
      disableFeature: () => {
        setDocuments([]);
        process.completeProcess();
      },
      submit: () => {
        return postAdditionalDocumentRequest({ documents: documents })
          .then(() => {
            setDocuments([]);
            process.completeProcess();
          })
          .catch(() => errorHandler.apiError());
      },
      addUpdateDocuments: (newDocuments: Array<ApplicationAdditionalDocumentRequestDocument>) => {
        let updatedDocuments = documents;
        newDocuments.forEach((newDocument) => {
          let isNew = true;
          updatedDocuments = updatedDocuments.map((doc) => {
            if (doc.type === newDocument.type && doc.sectionReference === newDocument.sectionReference) {
              isNew = false;
              return { ...doc, comment: newDocument.comment };
            }
            return doc;
          });
          if (isNew) updatedDocuments.push(newDocument);
        });
        setDocuments(updatedDocuments);
      },
      removeDocuments: (oldDocuments: Array<ApplicationAdditionalDocumentRequestDocument>) => {
        const updatedDocuments = documents.filter((doc) => {
          return !oldDocuments.some((oldDoc) => {
            return oldDoc.type === doc.type && oldDoc.sectionReference === doc.sectionReference;
          });
        });
        setDocuments(updatedDocuments);
      },
      setDocuments: (newDocuments: Array<ApplicationAdditionalDocumentRequestDocument>) => {
        setDocuments(newDocuments);
      },
    }),
    [documents, expandAll, process, errorHandler, postAdditionalDocumentRequest]
  );

  return (
    <applicationAdditionalDocumentRequestContext.Provider value={value}>
      {props.children}
    </applicationAdditionalDocumentRequestContext.Provider>
  );
}

export function useAdditionalDocumentRequest() {
  const state = React.useContext(applicationAdditionalDocumentRequestContext);
  if (state === undefined) {
    throw new Error(
      'useApplicationAdditionalDocumentRequest must be used within a ApplicationAdditionalDocumentRequestContextProvider'
    );
  }
  return {
    enableFeature: state.enableFeature,
    disableFeature: state.disableFeature,
    isEnabled: state.isEnabled,
    documents: state.documents,
    submit: state.submit,
    addUpdateDocuments: state.addUpdateDocuments,
    removeDocuments: state.removeDocuments,
    setDocuments: state.setDocuments,
  };
}
