import styled from '@emotion/styled';

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

type Breakpoint = keyof typeof breakpoints;

function up(b: Breakpoint) {
  return `@media (min-width: ${breakpoints[b]}px)`;
}

export function AccessibilityLinks() {
  return (
    <Wrapper>
      <Skip
        href="#"
        onClick={(e) => {
          e.preventDefault();
          const container: HTMLElement | null = document.querySelector('main h1');

          if (container) {
            container.tabIndex = -1;
            container.focus();
            setTimeout(() => container.removeAttribute('tabIndex'), 1000);
          }
        }}
      >
        Skip to main content
      </Skip>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  position: 'absolute',
  top: 75,
  left: 10,
  zIndex: 9999,
  [up('sm')]: {
    left: 80,
  },
});

const Skip = styled.a({
  ':not(:focus)': {
    clip: 'rect(1px,1px,1px,1px)',
    overflow: 'hidden',
    position: 'absolute',
    padding: 0,
  },
  ':focus': {
    border: '1px solid #1E6DEB',
    borderRadius: 2,
    fontSize: 14,
    padding: '3px 6px',
    display: 'inline-block',
    textAlign: 'center',
  },
});
