import { Select } from '@applyboard/crystal-ui';
import { useFilterField } from '../../hooks/useFilterField';
import { useStaticProps } from '../../context';

export const IntakeTermFilter = () => {
  const intakeTermFilter = useFilterField('intakeTermId', 'PrimaryFilter');
  const {
    referenceData: { intakeTerms },
  } = useStaticProps();

  return (
    <Select
      value={intakeTermFilter.value ? intakeTermFilter.value : 'Select Intake Term'}
      aria-label="Select Intake Term"
      placeholder="Select Intake Term"
      size="md"
      appearance="styled"
      onChange={(selectedItem) => intakeTermFilter.setValue(selectedItem)}
    >
      {Object.entries(intakeTerms).map(([id, { name }]) => (
        <Select.Option label={name} value={id} key={id} />
      ))}
    </Select>
  );
};
