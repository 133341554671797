import { Flex, Table, Tag, Text, ButtonLink, SrOnly, TableColumnSort } from '@applyboard/crystal-ui';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { Application, SortableFields } from './../data/useApplications';
import { ChevronRightOutlineIcon } from './../../../icons';
import { Flag } from './../../../components/Flag';
import { useSearchResult } from './../context';
import { formatDate } from '~/utils';
import React from 'react';
import { useFilterField } from './../hooks/useFilterField';

const columnMappings: Record<string, SortableFields> = {
  intakeTerm: 'programIntake.program.name',
  '-intakeTerm': '-programIntake.program.name',
  submittedAt: 'submittedAt',
  '-submittedAt': '-submittedAt',
  status: 'status',
  '-status': '-status',
};

export function ApplicationsTable() {
  const { apiError } = useErrorHandler();
  const sortFilter = useFilterField('sort', 'SearchResult');
  const [sortTable, setSortTable] = React.useState<TableColumnSort>([]);
  const { applications, isFetchingApplications, isApplicationsError } = useSearchResult();

  if (isApplicationsError) {
    apiError();
    return null;
  }

  return (
    <Flex direction="column">
      <div role="alert" aria-live="assertive">
        {isFetchingApplications && <SrOnly>Loading applications table</SrOnly>}
      </div>
      <Table
        sort={sortTable}
        onSort={(columns) => {
          /* c8 ignore next */
          if (columns.length === 0) return sortFilter.setValue(undefined);
          const column = columns[0];
          setSortTable(columns);
          sortFilter.setValue(columnMappings[column.desc ? `-${column.id}` : `${column.id}`]);
        }}
        columns={[
          {
            accessorKey: 'tags',
            header: () => <SrOnly>Update</SrOnly>,
            width: 'content',
            cell: (application: Application) => {
              const hasChanged =
                application.tags.includes('REQUESTED_DOCUMENT_COMPLETED') ||
                application.tags.includes('REQUESTED_DOCUMENT_UPDATED');
              if (hasChanged) {
                return (
                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      borderRadius: '100%',
                      backgroundColor: '#FF00CC',
                    }}
                    aria-label="DocumentTag"
                  ></div>
                );
              }
              return null;
            },
          },
          {
            accessorKey: 'humanReadableId',
            header: 'App. ID',
          },
          {
            accessorKey: 'applicantName',
            header: 'Applicant Name',
            cell: (application: Application) => {
              const applicant = application.applicant;
              const middleName = applicant.middleName ? ` ${applicant.middleName}` : '';
              const familyName = applicant.familyName ? ` ${applicant.familyName}` : '';
              return <Table.TextCell>{`${applicant.givenName}${middleName}${familyName}`}</Table.TextCell>;
            },
          },
          {
            accessorKey: 'nationality',
            header: 'Nationality',
            cell: (application: Application) => {
              return (
                <Table.Cell>
                  <Flex align="center" id={`${application.applicant.nationality.alpha2Code}-country-code`}>
                    <Flag nationalityCode={application.applicant.nationality.alpha2Code} />
                    <Table.TextCell>{application.applicant.nationality.alpha3Code}</Table.TextCell>
                  </Flex>
                </Table.Cell>
              );
            },
          },
          {
            enableSorting: true,
            accessorKey: 'intakeTerm',
            header: 'Program Intake',
            cell: (application: Application) => {
              return (
                <Flex direction="column" gap={1} p={2} align="start">
                  <Text as="div" contrast="high" id="" intent="secondary" variant="bodyM" overflow="ellipsis">
                    {application.programIntake.program.name}
                  </Text>
                  <Tag intent="secondary" size="sm">
                    {application.programIntake.intakeTerm.name}
                  </Tag>
                </Flex>
              );
            },
          },
          {
            enableSorting: true,
            accessorKey: 'submittedAt',
            header: 'Date Submitted',
            cell: (application: Application) => {
              return (
                <Table.TextCell>{formatDate(new Date(application.submittedAt), { month: 'short' })}</Table.TextCell>
              );
            },
          },
          {
            enableSorting: true,
            accessorKey: 'status',
            header: 'Status',
            cell: (application: Application) => {
              return (
                <>
                  {application.hasOpenDocumentRequests &&
                    !application.status.code.startsWith('DOCUMENTS_REQUESTED') && (
                      <div aria-label="Application has open document requests">
                        <Tag icon="fill" intent="warning" size="sm">
                          Document Requested
                        </Tag>
                      </div>
                    )}
                  <div aria-label={`Application Status Is ${application.status.label}`}>
                    <Tag icon="fill" intent={application.status.intent} size="sm">
                      {application.status.label}
                    </Tag>
                  </div>
                </>
              );
            },
          },
          {
            accessorKey: 'link',
            header: () => <SrOnly>Application Link</SrOnly>,
            cell: (application: Application) => {
              return (
                <ButtonLink
                  aria-label={`Application ${application.humanReadableId} Link`}
                  emphasis="transparent"
                  href={`/applications/${application.id}`}
                  intent="secondary"
                  leadIcon={() => {
                    return <ChevronRightOutlineIcon size="md" />;
                  }}
                  size="sm"
                  width="hug"
                />
              );
            },
          },
        ]}
        data={applications}
        loading={isFetchingApplications}
        overflow="scroll"
      />
    </Flex>
  );
}
