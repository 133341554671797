export function formatDate(date: Date | undefined | null, options: Intl.DateTimeFormatOptions = {}): string {
  if (!date) {
    return '';
  }

  const formattedDate = date.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...options,
  });

  return formattedDate;
}

export function formatDateToLocal(date: Date | undefined | null): string {
  if (!date) {
    return '';
  }

  const formattedDate = new Intl.DateTimeFormat('en-CA', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  }).format(date);

  return formattedDate;
}

export function formatNonTimezoneDate(dateStr: Date | undefined | null): string {
  if (!dateStr) {
    return '';
  }
  const formattedDate = Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(dateStr));

  return formattedDate;
}
