import { HttpError } from '~/lib/useRequest';
import { FourOOne } from './401';
import { FourOFour } from './404';
import { FourTwoTwo } from './422';
import { FiveHundred } from './500';
import { useRouteError } from 'react-router-dom';
import { useEventTracking } from '~/hooks/useEventTracking';

function getErrorPage(errorCode?: number) {
  const code = errorCode || 500;
  switch (code) {
    case 404:
      return FourOFour;
    case 422:
      return FourTwoTwo;
    case 401:
    case 403:
      return FourOOne;
    default:
      return FiveHundred;
  }
}

interface IErrorPageProps {
  errorCode?: number;
  customMessage?: string;
}

type ErrorHandlerProps = {
  error: unknown;
  customMessage?: string;
  defaultStatus?: number;
};

export const ErrorPage = ({ errorCode, customMessage }: IErrorPageProps) => {
  const Page = getErrorPage(errorCode);

  return <Page customMessage={customMessage} />;
};

export const ErrorHandler = ({ error, customMessage, defaultStatus = 500 }: ErrorHandlerProps) => {
  let status = defaultStatus;
  if (HttpError.verifyIfObjectIsOfTypeHttpError(error)) {
    status = (error as HttpError).status;
  }
  const Page = getErrorPage(status);

  return <Page customMessage={customMessage} />;
};

export const ErrorElement = () => {
  const error = useRouteError();
  const { trackError } = useEventTracking('Error Element');
  trackError({ event: 'Error Element', error: error as Error });
  return <ErrorPage errorCode={500} />;
};
