import { useQuery } from '@tanstack/react-query';
import { useRequest } from '~/lib/useRequest';
import { ApplicationSortableFields, ApplicationsPagePropsResponse } from '@ab/ams-school-api/types/private/v1';

import { config } from '~/config';

export type ReferenceData = ApplicationsPagePropsResponse['referenceData'];
export type InitialQueryParameter = {
  searchQuery?: string;
  pageSize: number;
  sort: ApplicationSortableFields;
};
export type ApplicationsPageInitialProps = ApplicationsPagePropsResponse;

export function useApplicationsPageProps() {
  const request = useRequest();
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['useApplicationsPageProps'],
    queryFn: async () => {
      const response = await request<ApplicationsPagePropsResponse>(
        `${config.apiHost}/private/v1/applications/page_props`,
        {
          isExpectedResponse,
        }
      );

      return response;
    },
  });
  return {
    isInitialPropsError: isError,
    initialPropsError: error,
    isLoadingInitialProps: isLoading,
    initialProps: data,
  };
}

function isExpectedResponse(res: unknown): res is ApplicationsPagePropsResponse {
  return Boolean(res && typeof res === 'object');
}
