import { useSession } from './../context';
import { useEventTracking as useGenericEventTracking } from '../../../hooks/useEventTracking';

type Group = 'Search Result' | 'Filter Summary' | 'Advanced Filter' | 'Filter Parameters';

export const useEventTracking = (group: Group) => {
  const sessionId = useSession();

  return useGenericEventTracking(group, sessionId);
};
