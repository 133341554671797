import { QueryParametersSchema, QueryParameterSchema, QueryParametersKey } from './types';

import { programIdFilterQueryParameter } from './programIdFilterQueryParameter';
import { intakeTermFilterQueryParameter } from './intakeTermFilterQueryParameter';
import { campusFilterQueryParameter } from './campusFilterQueryParameter';

const schema: QueryParametersSchema = {
  campusId: campusFilterQueryParameter,
  programId: programIdFilterQueryParameter,
  intakeTermId: intakeTermFilterQueryParameter,
};

const parameters = Object.entries(schema).map(([key, parameter]) => {
  return { key: key as keyof QueryParametersSchema, ...(parameter as QueryParameterSchema<QueryParametersKey>) };
});

export const get = <Key extends QueryParametersKey>(key: Key): QueryParametersSchema[Key] => schema[key];

export const queryParametersSchema = {
  get,
  parameters,
};
