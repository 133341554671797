import { useParams } from 'react-router-dom';
import { useApplication } from './data/useApplication';
import { Flex, Spinner } from '@applyboard/crystal-ui';
import { ApplicationDetailsLayout } from './layouts';
import { useWindowDocumentTitle } from '~/lib/useWindowDocumentTitle';
import { ApplicationDetailContext } from './context';
import { ErrorHandler } from '../ErrorPage';

export function ApplicationDetailsPage() {
  useWindowDocumentTitle('Application Details');
  const applicationId = useApplicationId();
  const { application, isLoadingApplication, applicationError, isApplicationError } = useApplication(applicationId); // applicationId always provided
  if (!isApplicationError && isLoadingApplication) {
    return (
      <Flex p={5} gap={5} align="center" direction="column" role="progressbar">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (isApplicationError || !application) {
    return <ErrorHandler error={applicationError} customMessage="Failed to fetch the application details." />;
  }

  return (
    <ApplicationDetailContext application={application}>
      <ApplicationDetailsLayout />
    </ApplicationDetailContext>
  );
}

function useApplicationId() {
  const { id } = useParams() as { id: string };
  return id;
}
