import React from 'react';
import { Notice, Dialog } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { AdditionalDocumentRequest } from '~/pages/ApplicationDetails/data/useApplication';
import { AdditionalDocumentsRequestDetails } from './AdditionalDocumentsRequestDetails';

type RequestDetailsModalProps = {
  onClose: () => void;
  additionalDocumentRequest: AdditionalDocumentRequest;
};
export function RequestDetailsModal(props: RequestDetailsModalProps) {
  const { onClose, additionalDocumentRequest } = props;

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <Dialog.Heading>Additional Documents Request</Dialog.Heading>
      <Dialog.Content>
        <AdditionalDocumentsRequestDetails additionalDocumentRequest={additionalDocumentRequest} />
      </Dialog.Content>
    </Dialog>
  );
}

export function AdditionalDocumentNotice() {
  const { application } = useAppProcessing();
  const [showRequests, setShowRequests] = React.useState(false);
  const { tags } = application;

  if (!application.meta.additionalDocumentRequest) return null;

  const intent = tags?.includes('REQUESTED_DOCUMENT_PENDING') ? 'warning' : 'primary';
  const notice = tags?.includes('REQUESTED_DOCUMENT_PENDING')
    ? 'Documents have been requested that are pending submission by the applicant.'
    : 'New documents have been submitted by the applicant ready to be reviewed';

  return (
    <Notice intent={intent} width="fill">
      {notice}
      <Notice.Button
        onClick={() => {
          setShowRequests(true);
        }}
      >
        View request
      </Notice.Button>
      {showRequests && (
        <RequestDetailsModal
          onClose={() => setShowRequests(false)}
          additionalDocumentRequest={application.meta.additionalDocumentRequest}
        />
      )}
    </Notice>
  );
}

export const sectionsMap = {
  educationHistory: 'Education History',
  languageProficiency: 'Language Proficiency',
  supportingDocuments: 'Other Required Documents',
  statusAndCitizenship: 'Status And Citizenship',
  application: 'Application',
} as const;
export type SectionKey = keyof typeof sectionsMap;
