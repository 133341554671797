import React, { createContext, useContext, useMemo, useState } from 'react';
import { FileData, DocumentType, UploadStatus } from 'applications-types-lib';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { Attachment } from '~/pages/ApplicationDetails/data/useApplication';
import { UploadFileData } from '../types';

interface IApplicationUploadContext {
  addPendingUpload: (fileId: string, uploadFileData: UploadFileData) => void;
  deleteUploadingFile: (fileId: string) => void;
  pendingFileUploadState: Record<string, UploadFileData | null>;
  pendingUpload: Record<string, UploadFileData>;
  uploadedFiles: FileData;
  visibleFileState: Record<string, Omit<UploadFileData, 'file'>>;
}

const ApplicationFileUploadContext = createContext<IApplicationUploadContext | undefined>(undefined);

export function useApplicationFileUploadContext(): IApplicationUploadContext {
  const context = useContext(ApplicationFileUploadContext);
  if (!context) {
    throw new Error('useApplicationFileUploadContext must be used within an ApplicationFileUploadProvider');
  }
  return context;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ApplicationFileUpload({ children }: { children: React.ReactNode }) {
  const { application } = useAppProcessing();

  let fileData: FileData = {};
  if (application.schoolAdditionalDocuments !== undefined) {
    fileData = convertAttachmentsToFileData(application.schoolAdditionalDocuments);
  }
  const [pendingDelete, setPendingDelete] = useState<string[]>([]);
  const [pendingUpload, setPendingUpload] = useState<Record<string, UploadFileData>>({});
  const [uploadedFiles, setUploadedFiles] = useState<FileData>(fileData || {});

  const visibleFileState: Record<string, Omit<UploadFileData, 'file'>> = useMemo(() => {
    const visibleUploadedFiles = Object.keys(uploadedFiles).reduce(
      (acc, id) => {
        const file = uploadedFiles[id];
        if (!file) return acc;

        acc[id] = {
          contentType: file.contentType as string,
          fileName: file.fileName as string,
          hash: file.hash as string,
          type: file.type as DocumentType,
          sectionReference: file.sectionReference as string,
          uploadedAt: file.uploadedAt as string,
        };
        return acc;
      },
      {} as Record<string, Omit<UploadFileData, 'file'>>
    );

    const combinedFiles = {
      ...visibleUploadedFiles,
      ...pendingUpload,
    };

    // Exclude files in pendingDelete
    const result = Object.keys(combinedFiles).reduce(
      (acc, id) => {
        if (!pendingDelete.includes(id)) {
          acc[id] = combinedFiles[id];
        }
        return acc;
      },
      {} as Record<string, Omit<UploadFileData, 'file'>>
    );

    return result;
  }, [pendingDelete, pendingUpload, uploadedFiles]);

  const pendingFileUploadState: Record<string, UploadFileData | null> = useMemo(() => {
    return {
      ...pendingUpload,
      ...pendingDelete.reduce(
        (acc, fileId) => {
          acc[fileId] = null;
          return acc;
        },
        {} as Record<string, null>
      ),
    };
  }, [pendingUpload, pendingDelete]);

  function addPendingUpload(fileId: string, uploadFileData: UploadFileData) {
    setPendingUpload((prevPendingUpload) => ({
      ...prevPendingUpload,
      [fileId]: uploadFileData,
    }));
  }

  function deleteUploadingFile(fileId: string) {
    setPendingUpload((prevPendingUpload) => {
      if (prevPendingUpload[fileId]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fileId]: _, ...rest } = prevPendingUpload;
        return rest;
      }
      return prevPendingUpload;
    });

    setUploadedFiles((prevUploadedFiles) => {
      if (prevUploadedFiles[fileId]) {
        setPendingDelete((prevPendingDelete) => {
          if (!prevPendingDelete.includes(fileId)) {
            return [...prevPendingDelete, fileId];
          }
          return prevPendingDelete;
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fileId]: _, ...rest } = prevUploadedFiles;
        return rest;
      }
      return prevUploadedFiles;
    });
  }
  function convertAttachmentsToFileData(attachments: Attachment[]): FileData {
    const fileData: FileData = {};

    attachments.forEach((attachment) => {
      const id = attachment.key;
      const documentType = attachment.type.code as DocumentType;

      fileData[id] = {
        fileName: attachment.fileName,
        type: documentType,
        sectionReference: attachment.sectionReference,
        uploadedAt: attachment.updatedAt || '',
        contentType: attachment.type.name,
        uploadStatus: attachment.uploadStatus ? attachment.uploadStatus : ('UPLOADED' as UploadStatus),
        hash: attachment.hash || '',
      };
    });

    return fileData;
  }
  return (
    <ApplicationFileUploadContext.Provider
      value={{
        addPendingUpload,
        deleteUploadingFile,
        pendingFileUploadState,
        pendingUpload,
        uploadedFiles,
        visibleFileState,
      }}
    >
      {children}
    </ApplicationFileUploadContext.Provider>
  );
}
