import React from 'react';
import { request, RequestParams } from './request';
import { useAuth } from '~/lib/useAuth';
import { useEventTracking } from '~/hooks/useEventTracking';
import { NetworkError, HttpError, UnexpectedResponseError } from './errors';

export function useRequest() {
  const auth = useAuth();
  const eventTracker = useEventTracking('Request');

  return React.useCallback(
    <T = unknown>(endpoint: string, params: RequestParams<T>) => {
      return request(endpoint, { ...params, authorization: auth.getAccessToken() }).catch((error) => {
        if (
          error instanceof NetworkError ||
          (error instanceof HttpError && error.status === 500) ||
          error instanceof UnexpectedResponseError
        ) {
          eventTracker.trackError({ error, event: 'request error' });
        }
        throw error;
      });
    },
    [auth, eventTracker]
  );
}
