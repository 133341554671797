import React from 'react';
import { MultiCombobox } from '~/components/uie-fix/MultiCombobox';
import { usePrograms } from './../../data/usePrograms';
import { useFilterField } from '../../hooks/useFilterField';
import useDebouncedState from '~/lib/useDebouncedState';

const API_REQUEST_DELAY = 300;

export const ProgramFilter = () => {
  const [, debouncedSearchInput, setSearchInput] = useDebouncedState('', API_REQUEST_DELAY);
  const programFilter = useFilterField('programId', 'PrimaryFilter');
  const { programs } = usePrograms({ 'filter[q]': debouncedSearchInput, 'page[size]': 10, 'page[number]': 1 });

  return (
    <MultiCombobox
      value={programFilter.value}
      aria-label="Program Name"
      placeholder="Program Name"
      onSearch={(query) => {
        // TODO: temp bug patch for UIE-1862
        if (query !== '[object Object]') setSearchInput(query);
      }}
      onChange={(selectedItems) => programFilter.setValue(selectedItems.filter((s) => !!s.trim()))}
      filterOption={(o) => !debouncedSearchInput || o.label.toLowerCase().includes(debouncedSearchInput.toLowerCase())}
      placeholderVariant="indicator"
    >
      {programs.length > 0 ? (
        programs.map((program) => <MultiCombobox.Option label={program.name} value={program.id} key={program.id} />)
      ) : (
        // TODO: temp bug patch for UIE-1862
        <MultiCombobox.Option label="SELECT" value="SELECT" />
      )}
    </MultiCombobox>
  );
};
