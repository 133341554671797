import { GridAlpha, Flex } from '@applyboard/crystal-ui';
import { StudentOutlineIcon } from '@applyboard/ui-icons';
import { formatNonTimezoneDate } from '~/utils/formatDate';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

import { Card } from './Card';
import { AttachedDocuments } from './shared/AttachedDocuments';

export function EducationHistory() {
  const {
    application: { educationHistories },
  } = useAppProcessing();

  return (
    <Card id="educationHistory" icon={StudentOutlineIcon} title="Education History">
      <Flex justify="start" direction="column" gap={10}>
        {educationHistories.map((eh) => (
          <Flex key={eh.key} justify="start" direction="column" gap={8}>
            <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
              <GridAlpha.Item rowStart={1} columnStart={1} columnEnd={3}>
                <LabelValue label="CREDENTIAL GAINED" variant="titleS" value={eh.credentialReceived} />
              </GridAlpha.Item>

              <GridAlpha.Item rowStart={2} columnStart={1} columnEnd={1}>
                <LabelValue label="EDUCATION LEVEL" value={eh.level.name} />
              </GridAlpha.Item>
              <GridAlpha.Item rowStart={2} columnStart={2} columnEnd={2}>
                <LabelValue label="EDUCATION COUNTRY" value={eh.country.countryLabel} />
              </GridAlpha.Item>

              <GridAlpha.Item rowStart={3} columnStart={1} columnEnd={1}>
                <LabelValue label="START DATE" value={formatNonTimezoneDate(new Date(eh.startDate))} />
              </GridAlpha.Item>
              <GridAlpha.Item rowStart={3} columnStart={2} columnEnd={2}>
                <LabelValue label="END DATE" value={formatNonTimezoneDate(new Date(eh.endDate))} />
              </GridAlpha.Item>
            </GridAlpha>
            <AttachedDocuments
              showTitle
              attachmentDocuments={eh.documents}
              section="educationHistory"
              sectionReference={eh.key}
            />
          </Flex>
        ))}
      </Flex>
    </Card>
  );
}
