import React from 'react';
import { Button, Dialog, Flex, Notice, Textarea } from '@applyboard/crystal-ui';
import { useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';
import { AttachmentTypeCode } from '~/pages/ApplicationDetails/data/useApplication';

type SingleDocumentRequestProps = {
  notice?: string;
  mode: 'ADD' | 'UPDATE';
  documentTypeName: string;
  sectionReference: string;
  documentTypeCode: AttachmentTypeCode;
};

export function SingleDocumentRequest(props: SingleDocumentRequestProps) {
  return (
    <SingleDocumentRequestDialog
      notice={props.notice}
      documentTypeName={props.documentTypeName}
      documentTypeCode={props.documentTypeCode}
      sectionReference={props.sectionReference}
      actionButtonName={props.mode === 'ADD' ? `Request ${props.documentTypeName}` : 'Request Document'}
      emphasis={props.mode === 'ADD' ? 'outlined' : 'transparent'}
      size={props.mode === 'ADD' ? 'md' : 'lg'}
    />
  );
}

type SingleDocumentRequestDialogProps = {
  notice?: string;
  documentTypeName: string;
  sectionReference: string;
  documentTypeCode: AttachmentTypeCode;
  actionButtonName: string;
  emphasis: 'outlined' | 'transparent';
  size: 'md' | 'lg';
};
function SingleDocumentRequestDialog(props: SingleDocumentRequestDialogProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const { documentTypeCode: type, sectionReference, actionButtonName, emphasis, size } = props;
  const { addUpdateDocuments, documents } = useAdditionalDocumentRequest();

  const activeDocumentReq = React.useMemo(
    () => documents.find((f) => f.sectionReference === sectionReference && f.type === type),
    [documents, type, sectionReference]
  );
  const [comment, setComment] = React.useState(() => activeDocumentReq?.comment || '');

  const onSave = React.useCallback(() => {
    addUpdateDocuments([{ type, comment, sectionReference }]);
    setOpen(false);
  }, [addUpdateDocuments, comment, sectionReference, type, setOpen]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (open) {
          setComment(activeDocumentReq?.comment || '');
        } else {
          setComment('');
        }
      }}
    >
      <Dialog.Heading>{`Request ${props.documentTypeName}`}</Dialog.Heading>
      <Dialog.Content>
        <Flex gap={6} direction="column">
          {props.notice && <Notice intent="secondary">{props.notice}</Notice>}
          <Textarea label="Notes for student" value={comment} onChange={(value) => setComment(value)} size="sm" />
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button disabled={!comment.trim()} intent="primary" width="fill" onClick={() => onSave()}>
          Save
        </Button>
      </Dialog.Actions>
      <Dialog.Trigger>
        <Button intent="primary" emphasis={emphasis} size={size}>
          {actionButtonName}
        </Button>
      </Dialog.Trigger>
    </Dialog>
  );
}
