import { usePrograms } from './../../data/usePrograms';
import { useFilterField } from '../../hooks/useFilterField';
import useDebouncedState from '~/lib/useDebouncedState';
import { Combobox } from '@applyboard/crystal-ui';

const API_REQUEST_DELAY = 300;

export const ProgramFilter = () => {
  const [, debouncedSearchInput, setSearchInput] = useDebouncedState('', API_REQUEST_DELAY);
  const programFilter = useFilterField('programId', 'PrimaryFilter');
  const { programs, isLoadingPrograms } = usePrograms({
    'filter[q]': debouncedSearchInput,
    'page[size]': 10,
    'page[number]': 1,
  });

  return (
    <div>
      <Combobox
        value={!isLoadingPrograms && programFilter.value ? programFilter.value : 'Select Program'}
        aria-label="Select Program"
        placeholder="Select Program"
        size="md"
        onSearch={(query) => setSearchInput(query)}
        onChange={(selectedItem) => programFilter.setValue(selectedItem)}
      >
        {programs.map((program) => (
          <Combobox.Option label={program.name} value={program.id} key={program.id} />
        ))}
      </Combobox>
    </div>
  );
};
