import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest, HttpError } from '~/lib/useRequest';
import {
  GetApplicationResponse,
  ApplicationResource,
  PatchApplicationRequestBody,
  PatchApplicationResponse,
  Attachment as BffAttachment,
} from '@ab/ams-school-api/types/private/v1';

export type ApplicationMeta = NonNullable<ApplicationResource['meta']>;
export type AdditionalDocumentRequest = NonNullable<ApplicationResource['meta']['additionalDocumentRequest']>;
export type Application = ApplicationResource['attributes'] & { id: string; meta: ApplicationMeta };
export type ApplicationStatus = Application['status'];
export type Applicant = Application['applicant'];
export type ApplicantAddress = Applicant['addresses']['residentialAddress'];
export type StatusAndCitizenship = Application['statusAndCitizenship'];
export type EducationHistory = Application['educationHistories'][number];
export type LanguageProficiency = Application['languageProficiency'];
export type Attachment = BffAttachment;
export type AttachmentTypeCode = Attachment['type']['code'];
export type DeferralRequest = NonNullable<ApplicationResource['meta']['deferralRequest']>;
export type DeferredProgramIntakeTerm = NonNullable<ApplicationResource['meta']['deferredProgramIntakeTerm']>;

export function useApplication(id: string) {
  const request = useRequest();

  const { isLoading, isFetching, error, data, isError } = useQuery({
    queryKey: ['application', id],
    queryFn: async () => {
      const response = await request<GetApplicationResponse>(`${config.apiHost}/private/v1/applications/${id}`, {
        method: 'GET',
        isExpectedResponse,
      });

      const app = response.data;
      const attrs = app.attributes;
      return {
        ...attrs,
        id: app.id,
        meta: app.meta,
      } as Application;
    },
  });
  return {
    isLoadingApplication: isLoading,
    isFetchingApplication: isFetching,
    applicationError: error,
    application: data,
    isApplicationError: isError,
  };
}

export function usePatchApplication(id: string) {
  const request = useRequest();
  const queryClient = useQueryClient();
  const { isLoading, error, mutateAsync } = useMutation(
    ['application', id],
    async (payload: PatchApplicationRequestBody['data']['attributes']) => {
      try {
        const response = await request<PatchApplicationResponse>(`${config.apiHost}/private/v1/applications/${id}`, {
          method: 'PATCH',
          body: {
            data: {
              id: id,
              type: 'application',
              attributes: payload,
            },
          } as PatchApplicationRequestBody,
          isExpectedResponse,
        });

        const app = response.data;
        const attrs = app.attributes;

        queryClient.invalidateQueries(['application', id]);

        return {
          ...attrs,
          id: app.id,
        } as Application;
      } catch (err) {
        if (isInvalidApplicationStatusError(err)) throw new HttpError(400, 'invalid status', err.response, err.body);
        throw err;
      }
    }
  );

  return {
    patchApplication: mutateAsync,
    patchApplicationError: error,
    isPatchingApplication: isLoading,
  };
}
function isExpectedResponse(res: unknown): res is PatchApplicationResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
function isInvalidApplicationStatusError(err: unknown | Error): err is HttpError {
  if (err instanceof HttpError && err.body?.errors?.[0]?.detail === 'Invalid application status') return true;
  return false;
}
