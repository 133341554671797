// TODO: should be addressed by UIE

import styled from '@emotion/styled';
import { AppBarHeight } from './consts';

export const FullHeightWidthLayout = styled.div({
  minHeight: '100vh',
  minWidth: '100%',
  paddingTop: AppBarHeight,
});

export const FixedAppbar = styled.div(({ theme }) => ({
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1720,
  width: '100%',
  position: 'fixed',
  height: `${AppBarHeight}`,
  backgroundColor: theme.colors.bgRegular,
  borderBottom: `1px solid ${theme.colors.bgSecondaryPressed}`,
}));

export const FixedSidebar = styled.div(({ theme }) => ({
  height: `calc(100vh - ${AppBarHeight})`,
  zIndex: 1720,
  position: 'sticky',
  top: AppBarHeight,
  borderRight: `1px solid ${theme.colors.bgSecondaryPressed}`,
}));

export const NavBarMenu = styled.div({
  width: `${AppBarHeight}`,
});

export const MainWrapper = styled.div({
  width: '100%',
});
