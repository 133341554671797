import React, { createContext, useContext, useMemo } from 'react';

import { queryParametersSchema } from '../schema';
import { useStaticProps } from './StaticPropsContext';
import { useQueryParameter } from './QueryParameterContext';
import { useEventTracking } from '../hooks/useEventTracking';
import { Stats, ReportsQuery, useReports } from '../data/useReports';

export interface ReportsResult {
  stats: Array<Stats>;

  dataIsReady: boolean;

  isPreviousStatsData: boolean;
  isLoadingStats: boolean;
  isFetchingStats: boolean;
  isStatsError: boolean;
}

const reportsResultContext = createContext<ReportsResult | undefined>(undefined);

export const ReportsResultContextProvider = ({ children }: { children: React.ReactNode }) => {
  const staticProps = useStaticProps();
  const { trackEvent } = useEventTracking('Applications Report Result');
  const { queryParameters, rnd, queryId } = useQueryParameter();

  const reportsQueryParams = useMemo(() => {
    const params = {} as ReportsQuery;
    queryParametersSchema.parameters.forEach((parameter) => {
      const value = parameter.getValue({ staticProps, queryParameters });
      const defaultValue = parameter.getDefaultValue({ initialQueryParameters: staticProps.initialQueryParameter });
      const result = parameter.toSearchQuery({
        value,
        staticProps,
        defaultValue,
        queryParameters,
      });
      if (!result) return;
      Object.assign(params, result);
    });
    return params;
  }, [staticProps, queryParameters]);

  const allDataAreProvided =
    !!queryParameters.campusId && !!queryParameters.intakeTermId && !!queryParameters.programId;

  const { stats, isLoadingStats, isFetchingStats, isPreviousStatsData, isStatsError } = useReports(reportsQueryParams, {
    enabled: allDataAreProvided,
    onSuccess: (data) => {
      trackEvent({
        event: 'Results Returned',
        data: {
          rnd,
          queryId,
          page_total_stats: data.length,
          queryParameters: queryParameters,
        },
      });
    },
  });

  const value = useMemo(
    () => ({
      stats,
      isLoadingStats,
      isFetchingStats,
      isPreviousStatsData,
      isStatsError,
      dataIsReady: allDataAreProvided,
    }),
    [stats, isLoadingStats, isFetchingStats, isPreviousStatsData, isStatsError, allDataAreProvided]
  );

  return <reportsResultContext.Provider value={value}>{children}</reportsResultContext.Provider>;
};

export const useReportsResult = () => {
  const value = useContext(reportsResultContext);
  if (value === undefined) {
    throw new Error('useReportsResult must be used within a ReportsResultContextProvider');
  }
  return value;
};
