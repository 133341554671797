import React from 'react';
import { Flex, Tag, Text } from '@applyboard/crystal-ui';
import { AdditionalDocumentRequest } from '~/pages/ApplicationDetails/data/useApplication';
import { formatDateToLocal } from '~/utils';

type Attachment = AdditionalDocumentRequest['documents'][number];

type SectionRequestProps = {
  section: SectionKey;
  documents: Attachment[];
};
function SectionRequest(props: SectionRequestProps) {
  const { section, documents } = props;
  const sectionTitle = sectionsMap[section];
  return (
    <Flex gap={6} direction="column" pb={2}>
      <Text variant="titleM">{sectionTitle}</Text>
      {documents.map((d) => (
        <Flex key={`${d.section}-${d.sectionReference}`} gap={4} direction="column">
          <Flex align="center" gap={4}>
            <Text variant="bodyL">{d.type.name}</Text>
            <Tag intent={d.status.code === 'PENDING' ? 'warning' : 'primary'}>{d.status.name}</Tag>
          </Flex>
          <Flex gap={2} direction="column">
            <Text variant="labelM" intent="secondary">
              Notes for student
            </Text>
            <Text variant="bodyM">{d.comment!}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

type DocumentsRequestDetailsProps = {
  onlyPendings?: boolean;
  additionalDocumentRequest: AdditionalDocumentRequest;
};
export function AdditionalDocumentsRequestDetails(props: DocumentsRequestDetailsProps) {
  const { additionalDocumentRequest } = props;

  const groups = React.useMemo(() => {
    const result = {} as Record<SectionKey, Array<Attachment>>;
    additionalDocumentRequest.documents
      .filter((doc) => !!doc && (!props.onlyPendings || doc.status.code === 'PENDING'))
      .forEach((doc) => {
        const section = doc.section as SectionKey;
        if (!result[section]) result[section] = [];
        result[section].push(doc);
      });

    return Object.entries(result).reduce(
      (acc, [k, v]) => [
        ...acc,
        {
          section: k as SectionKey,
          documents: v,
        },
      ],
      [] as Array<{
        section: SectionKey;
        documents: Array<Attachment>;
      }>
    );
  }, [additionalDocumentRequest, props.onlyPendings]);

  const requestedAtDateString = formatDateToLocal(new Date(additionalDocumentRequest.requestedAt));
  return (
    <Flex gap={6} direction="column">
      <Flex direction="row" gap={2}>
        <Text id="document-request-date" variant="labelM">
          Date Requested
        </Text>
        <Text variant="bodyM" aria-labelledby="document-request-date">
          {requestedAtDateString}
        </Text>
      </Flex>
      <>
        {groups.map((group) => (
          <SectionRequest key={group.section} section={group.section as SectionKey} documents={group.documents} />
        ))}
      </>
    </Flex>
  );
}

export const sectionsMap = {
  educationHistory: 'Education History',
  languageProficiency: 'Language Proficiency',
  supportingDocuments: 'Other Required Documents',
  statusAndCitizenship: 'Status And Citizenship',
  application: 'Application',
} as const;
export type SectionKey = keyof typeof sectionsMap;
