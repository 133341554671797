import { RouteObject, useMatches as baseUseMatches } from 'react-router-dom';

import { ErrorElement } from '~/pages/ErrorPage/index';
import { ReportsPage } from './pages/Reports';
import { IconType, ClipboardOutlineIcon, BarGraphFillIcon } from './icons';
import { ApplicationsPage } from './pages/Applications';
import { ApplicationDetailsPage } from './pages/ApplicationDetails';

export type Route = {
  name: string;
  path: string;
  crumb?: string;
  icon: IconType;
  hidden?: boolean;
  children?: Route[];
  page: React.ReactNode;
};

export const routes: Route[] = [
  {
    name: 'Applications',
    crumb: 'Applications',
    icon: ClipboardOutlineIcon,
    path: '/applications',
    page: <ApplicationsPage />,
    children: [
      {
        name: 'Application Detail',
        crumb: 'Application Detail',
        path: ':id',
        icon: ClipboardOutlineIcon,
        page: <ApplicationDetailsPage />,
      },
    ],
  },
  {
    name: 'Reports',
    crumb: 'Reports',
    icon: BarGraphFillIcon,
    path: '/reports',
    page: <ReportsPage />,
  },
];

export const reactRouterV6Routes = routes.map((route) => convertToReactV6(route));

export function useMatches() {
  const matches = baseUseMatches();
  return matches.filter((m) => m.handle).map((m) => m.handle as Route);
}

function convertToReactV6(route: Route): RouteObject {
  if (route.children && route.children.length) {
    return {
      path: route.path,
      handle: route,
      children: [
        {
          path: '',
          index: true,
          element: route.page,
          errorElement: <ErrorElement />,
        },
        ...route.children.map((childRoute) => convertToReactV6(childRoute)),
      ],
    };
  } else {
    return {
      handle: route,
      path: route.path,
      element: route.page,
      errorElement: <ErrorElement />,
      children: route.children?.map((childRoute) => convertToReactV6(childRoute)),
    };
  }
}
