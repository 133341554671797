import React from 'react';
import { Tabs } from '@applyboard/crystal-ui';

type ApplicationDetailsTabsProps = {
  submittedApplicationDetails: React.ReactNode;
  applicationDetails: React.ReactNode;
};

export function ApplicationDetailsTabs(props: ApplicationDetailsTabsProps) {
  const { submittedApplicationDetails, applicationDetails } = props;
  const [activeTab, setActiveTab] = React.useState('1');
  return (
    <Tabs align="left" onChange={setActiveTab} value={activeTab} orientation="horizontal">
      <Tabs.Item label="Submitted" value="1">
        {submittedApplicationDetails}
      </Tabs.Item>
      <Tabs.Item label="Details" value="2">
        {applicationDetails}
      </Tabs.Item>
    </Tabs>
  );
}
