import React from 'react';
import { Text, Flex, Spinner } from '@applyboard/crystal-ui';

import { BarChart } from './BarChart';
import { useReportsResult } from '../../context';
import { default as WarningIcon } from './assets/warning-icon.svg';

export function ApplicationStats() {
  const { stats, isLoadingStats, dataIsReady } = useReportsResult();

  if (!isLoadingStats && !stats) {
    return (
      <Flex p={5} gap={5} align="center" direction="column" role="progressbar">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex gap={10} direction="column">
      <Flex direction="column">
        <Text variant="titleM">Applications funnel</Text>
        <Text variant="bodyS" intent="secondary">
          The following chart shows the progression of applicants through the application states for the selected
          intake.
        </Text>
      </Flex>
      {dataIsReady && <BarChart stats={stats} />}
      {!dataIsReady && (
        <Flex justify="center" direction="column" align="center" pt={20}>
          <img src={WarningIcon} width="112.96px" height="112.31px" alt="No Data Available to Display" />
          <Text intent="secondary" variant="titleM">
            No Data Available to Display
          </Text>
          <Text intent="secondary" variant="bodyM">
            Please make a selection in all the above fields to generate results
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
