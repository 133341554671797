import React from 'react';
import { MultiCombobox } from '~/components/uie-fix/MultiCombobox';
import { useStaticProps } from '../../context';
import { useFilterField } from '../../hooks/useFilterField';

export const NationalityFilter = () => {
  const nationalityFilter = useFilterField('nationality', 'PrimaryFilter');
  const { referenceData } = useStaticProps();

  const nationalities = React.useMemo(
    () =>
      Object.entries(referenceData.nationalities)
        .map(([k, v]) => ({ label: v.nationalityLabel, value: k }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [referenceData.nationalities]
  );

  return (
    <MultiCombobox
      value={nationalityFilter.value}
      aria-label="Nationality"
      placeholder="Nationality"
      onChange={(selectedItems) => nationalityFilter.setValue(selectedItems.filter((s) => !!s.trim()))}
      placeholderVariant="indicator"
    >
      {Object.entries(nationalities).map(([id, { label, value }]) => (
        <MultiCombobox.Option label={label} value={value} key={id} />
      ))}
    </MultiCombobox>
  );
};
