import { useAppProcessing, useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';

import { ActionGroup, Action } from './ActionGroup';
import { NoticeContextProvider } from './useNotice';
import { DeclineDialog } from './Decline/DeclineDialog';
import { PreOfferDialog } from './IssuePreOffer/PreOfferDialog';
import { AdditionalDocumentRequest } from './AdditionalDocumentRequest';
import { WaitlistLetterDialog } from './Waitlisted/WaitlistLetterDialog';
import { FinalOfferDialog } from './IssueFinalOffer/FinalOfferDialog';
import { PassScreeningDialog, usePassScreeningActivity } from './passScreening';
import { DeferralRequestDialog } from './DeferralRequest/DefferalRequestDialog';

export function ApplicationProcessing() {
  const additionalDocumentRequest = useAdditionalDocumentRequest();
  const { activeProcess, startProcess, application } = useAppProcessing();
  const { passScreening } = usePassScreeningActivity();

  function onAction(action: Action) {
    if (action.code === 'REQUEST_DOCUMENTS') {
      return additionalDocumentRequest.enableFeature();
    }

    switch (action.nextStatus) {
      case 'DECLINED':
        return startProcess('decline');
      case 'INITIAL_OFFER':
        return startProcess('issuePreOffer');
      case 'FINAL_OFFER_ISSUED':
        return startProcess('issueFinalOffer');
      case 'WAITLISTED':
        return startProcess('waitlisted');
      case 'READY_FOR_ASSESSMENT':
        return passScreening();
      case 'DOCUMENTS_REQUESTED_IN_ASSESSMENT':
      case 'DOCUMENTS_REQUESTED_IN_SCREENING':
        return additionalDocumentRequest.enableFeature();
    }
  }

  if (activeProcess === 'additionalDocumentRequest') return <AdditionalDocumentRequest />;

  return (
    <NoticeContextProvider>
      {activeProcess === 'decline' && <DeclineDialog />}
      {activeProcess === 'issuePreOffer' && <PreOfferDialog applicationId={application.id} />}
      {activeProcess === 'issueFinalOffer' && <FinalOfferDialog applicationId={application.id} />}
      {activeProcess === 'waitlisted' && <WaitlistLetterDialog applicationId={application.id} />}
      {activeProcess === 'deferralRequestPreOffer' && (
        <PreOfferDialog applicationId={application.id} patchDeferralRequest />
      )}
      {activeProcess === 'passScreening' && <PassScreeningDialog />}
      {activeProcess === 'deferralRequest' && <DeferralRequestDialog />}
      {application.status.actionGroups.some((g) => g.actions.length > 0) &&
        application.status.actionGroups.map((actionGroup) => (
          <ActionGroup key={actionGroup.title} actionGroup={actionGroup} onAction={onAction} />
        ))}
    </NoticeContextProvider>
  );
}
