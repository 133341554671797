import React from 'react';
import { Flag } from '~/components/Flag';
import { formatNonTimezoneDate } from '~/utils';
import { PersonOutlineIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { Flex, Heading, Text, GridAlpha } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { ApplicantAddress } from '~/pages/ApplicationDetails/data/useApplication';

import { Card } from './Card';

function ApplicantInfo() {
  const {
    application: { applicant },
  } = useAppProcessing();
  return (
    <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
      <LabelValue label="GIVEN NAME" value={applicant.givenName} />
      <LabelValue label="FAMILY NAME" value={applicant.familyName || '--'} />
      <LabelValue label="MIDDLE NAME" value={applicant.middleName || '--'} />

      <LabelValue label="DATE OF BIRTH" value={formatNonTimezoneDate(new Date(applicant.dateOfBirth))} />
      <LabelValue label="GENDER" value={applicant.gender.name} />
      <LabelValue
        label="NATIONALITY"
        value={applicant.nationality.nationalityLabel}
        icon={<Flag height={10} nationalityCode={applicant.nationality.alpha2Code} />}
      />
    </GridAlpha>
  );
}
function AddressDetails(props: { heading: string; address: ApplicantAddress }) {
  const { address } = props;
  return (
    <Flex gap={8} direction="column" role="region" aria-describedby={props.heading}>
      <Heading level={4} variant="titleS">
        {props.heading}
      </Heading>
      <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
        <GridAlpha.Item rowStart={1} columnStart={1} columnEnd={2}>
          <LabelValue label="COUNTRY" value={address.country.countryLabel} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={2} columnStart={1} columnEnd={1}>
          <LabelValue label="ADDRESS LINE 1" value={address.addressLine1} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={2} columnStart={2} columnEnd={2}>
          <LabelValue label="ADDRESS LINE 2" value={address.addressLine2 || '--'} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={3} columnStart={1} columnEnd={1}>
          <LabelValue label="CITY" value={address.city} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={3} columnStart={2} columnEnd={2}>
          <LabelValue label="PROVINCE/STATE/AREA" value={address.province} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={4} columnStart={1} columnEnd={2}>
          <LabelValue label="POSTAL/ZIP CODE" value={address.postalCode || '--'} />
        </GridAlpha.Item>
      </GridAlpha>
    </Flex>
  );
}

export function ApplicantInformation() {
  const {
    application: { applicant },
  } = useAppProcessing();
  const isMailingAddressSameAsResidential = applicant.addresses.isMailingAddressSameAsResidential;

  return (
    <Card id="applicant" icon={PersonOutlineIcon} title="Applicant Information">
      <Flex gap={8} direction="column">
        <ApplicantInfo />
        <AddressDetails heading="Residential Address" address={applicant.addresses.residentialAddress} />
        {isMailingAddressSameAsResidential ? (
          <Text>Mailing address is the same as shipping address.</Text>
        ) : (
          <AddressDetails heading="Mailing Address" address={applicant.addresses.mailingAddress} />
        )}
      </Flex>
    </Card>
  );
}
