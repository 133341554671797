import React from 'react';
import styled from '@emotion/styled';
import { ButtonLink, ButtonLinkProps } from '@applyboard/crystal-ui';

export function TruncatedButtonLink(props: ButtonLinkProps) {
  const containerRef = React.useRef<HTMLDivElement>();
  const [parentWidth, setParentWidth] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && containerRef.current?.parentElement?.parentElement) {
        if (containerRef.current.clientWidth > containerRef.current.parentElement.parentElement.clientWidth) {
          const newWidth = containerRef.current.parentElement.parentElement.clientWidth;
          setParentWidth(newWidth);
        }
      }
    };

    handleResize(); // Call once to initialize the width

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Wrapper ref={containerRef as React.LegacyRef<HTMLDivElement>} width={parentWidth} style={{ width: parentWidth }}>
      <ButtonLink {...props} />
    </Wrapper>
  );
}

const Wrapper = styled.div((props: { width?: number }) => ({
  width: `${!props.width ? undefined : props.width - 100}px`,
  'a > div > div > span:nth-of-type(2)': {
    width: `${!props.width ? undefined : props.width - 100}px`,
    textDecoration: 'none !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
}));
