import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AppBar } from './AppBar';
import { Sidebar } from './Sidebar';
import SchoolLogo from './ApplyBoardLogo.svg';
import { LoadingPage } from '../../pages/LoadingPage';

import { FixedAppbar, FullHeightWidthLayout, FixedSidebar, MainWrapper } from './styles';
import { Flex } from '@applyboard/crystal-ui';
import { useAuth } from '~/lib/useAuth';
import { routes } from './../../Routes';
import { FourOOne } from '~/pages/ErrorPage/401';

const visibleRoutes = routes.filter((route) => !route.hidden);

export function Layout() {
  const auth = useAuth();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  if (auth.isLoading) return <LoadingPage />;
  if (!auth.isAuthenticated) return <FourOOne />;

  return (
    <FullHeightWidthLayout>
      <Flex direction="row">
        <FixedAppbar data-testid="header">
          <AppBar
            title="ACME University"
            logo={SchoolLogo}
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
          />
        </FixedAppbar>
        <MainWrapper>
          <Flex direction="row" align="start" gap={0} height="fill">
            <FixedSidebar>
              <Sidebar>
                {visibleRoutes.map((route) => (
                  <Sidebar.Item
                    key={route.name}
                    href={route.path}
                    title={route.name}
                    icon={route.icon}
                    isSidebarOpen={isSidebarOpen}
                  />
                ))}
              </Sidebar>
            </FixedSidebar>
            <Flex as="main" grow={1} direction="column" gap={0}>
              <Outlet />
            </Flex>
          </Flex>
        </MainWrapper>
      </Flex>
    </FullHeightWidthLayout>
  );
}
