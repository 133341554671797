import { Button, Flex, Text, Heading } from '@applyboard/crystal-ui';
import { ArrowLeftOutlineIcon } from '@applyboard/ui-icons';
export const STEPS = {
  STEP1: 1,
  STEP2: 2,
} as const;

export function DialogHeader(props: { stepState: number; setStepState: (stepState: number) => void; heading: string }) {
  return (
    <Flex direction="row" gap={2}>
      {props.stepState > STEPS.STEP1 && (
        <Button
          aria-label="backButton"
          intent="secondary"
          emphasis="highlighted"
          leadIcon={ArrowLeftOutlineIcon}
          onClick={() => props.setStepState(props.stepState - 1)}
        />
      )}
      <Flex direction="column">
        <Text variant="labelS" intent="secondary" contrast="mid">
          STEP {props.stepState}/2
        </Text>
        <Heading level={1} variant="titleM" intent="secondary">
          {props.heading}
        </Heading>
      </Flex>
    </Flex>
  );
}
