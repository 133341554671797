import React from 'react';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { usePatchDeferralRequest } from '~/pages/ApplicationDetails/data/usePatchDeferralRequest';

export function useDeferralRequestActivity() {
  const process = useAppProcessing();

  const { patchDeferralRequest } = usePatchDeferralRequest(process.application.id);
  const [isBusy, setIsBusy] = React.useState(false);
  const [isAccepting, setIsAccepting] = React.useState(false);

  async function processDeferralRequest(props: {
    accept: boolean;
    conditions?: Array<string>;
    customCondition?: string;
    additionalNotes?: string;
  }) {
    const status = props.accept ? 'DEFERRAL_ACCEPTED' : 'DEFERRAL_REJECTED';
    setIsAccepting(props.accept);
    setIsBusy(true);
    return patchDeferralRequest({
      id: process.application.meta.deferralRequest!.id,
      attributes: {
        status,
        conditions: props.conditions,
        customCondition: props.customCondition,
        additionalNotes: props.additionalNotes,
      },
    })
      .then((result) => result)
      .finally(() => setIsBusy(false));
  }

  function isDeferralRequest(code: string) {
    return (
      code === 'DEFERRAL_REQUESTED_IN_SCREENING' ||
      code === 'DEFERRAL_REQUESTED_IN_ASSESSMENT' ||
      code === 'DEFERRAL_REQUESTED_IN_INITIAL_OFFER'
    );
  }

  return {
    isBusy,
    isAccepting,
    processDeferralRequest,
    completeProcess: () => process.completeProcess(),
    startPreOffer: () => process.startProcess('deferralRequestPreOffer'),
    isDeferralRequest,
  };
}
