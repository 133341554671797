import React from 'react';
import { Dialog, Button, Text, Divider } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { AdditionalDocumentsRequestDetails } from './../AdditionalDocumentRequest/AdditionalDocumentsRequestDetails';

import { usePassScreeningActivity } from './usePassScreeningActivity';

export function PassScreeningDialog() {
  const [isBusy, setIsBusy] = React.useState(false);
  const { completeProcess, application } = useAppProcessing();
  const { submitPassScreening } = usePassScreeningActivity();

  const additionalDocumentRequest = application.meta.additionalDocumentRequest!;

  const onSave = React.useCallback(() => {
    setIsBusy(true);
    return submitPassScreening().finally(() => setIsBusy(false));
  }, [submitPassScreening]);

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open && !isBusy) completeProcess();
      }}
    >
      <Dialog.Heading>Pass Screening</Dialog.Heading>
      <Dialog.Content>
        <Text>
          Are you sure you want to pass screening? This action will override and dismiss your request for any pending
          documents. The applicant will not be expected to submit pending documents and the application will move
          forward to assessment.
        </Text>
        <Divider />
        <AdditionalDocumentsRequestDetails additionalDocumentRequest={additionalDocumentRequest} onlyPendings />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill" disabled={isBusy}>
          Cancel
        </Dialog.CloseButton>
        <Button intent="primary" width="fill" onClick={() => onSave()} loading={isBusy}>
          Yes, continue to assessment
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
