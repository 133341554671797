import { formatCurrency, formatDate } from '~/utils';
import { Flex, Heading, Tag, Divider, Card, Text } from '@applyboard/crystal-ui';

import { useAppProcessing } from '~/pages/ApplicationDetails/context';

function AttributesView(props: { items: Array<{ label: string; value: string }>; line: string }) {
  return (
    <Flex gap={1} align="end" direction="row" id={`program-line-${props.line}`}>
      {props.items.map((item, idx) => (
        <Flex key={item.label} gap={3} direction="row" pr={2}>
          <Flex direction="row" gap={1} aria-describedby={item.label}>
            <Text id={item.label} variant="labelM">
              {item.label}
            </Text>
            <Text variant="bodyM" aria-labelledby={item.label}>
              {item.value}
            </Text>
          </Flex>
          {idx !== props.items.length - 1 && <Divider direction="vertical" />}
        </Flex>
      ))}
    </Flex>
  );
}

type ProgramDetailsProps = { lineSplit: boolean };
export function ProgramDetails(props: ProgramDetailsProps) {
  const { application } = useAppProcessing();
  const { program, campus, intakeTerm } = application.programIntake;
  const items = [
    { label: 'Program Code', value: program.code },
    { label: 'Campus', value: campus.name },
    { label: 'Application ID', value: application.humanReadableId },
    { label: 'Date Submitted', value: formatDate(new Date(application.submittedAt)) },
    { label: 'Application Fee Status', value: application.applicationFee.isFree ? 'Free' : 'Paid' },
    { label: 'Amount Paid', value: formatCurrency(application.applicationFee.amount) },
  ];
  let split = items.length;
  if (props.lineSplit) {
    split = 3;
  }
  return (
    <Card emphasis="highlighted">
      <Flex direction="column">
        <Flex gap={3} align="center">
          <Heading level={2}>{program.name}</Heading>
          <Tag intent="secondary" size="md">
            {intakeTerm.name}
          </Tag>
          {application.meta.deferredProgramIntakeTerm && (
            <Text
              intent="secondary"
              contrast="mid"
              variant="labelS"
            >{`Deferred from ${application.meta.deferredProgramIntakeTerm.name}`}</Text>
          )}
        </Flex>
        <Divider direction="horizontal" />
        <AttributesView items={items.slice(0, split)} line="1" />
        {props.lineSplit && <AttributesView items={items.slice(split, items.length)} line="2" />}
      </Flex>
    </Card>
  );
}
