import { Flex, Button } from '@applyboard/crystal-ui';
import { useApplicationCards } from '~/pages/ApplicationDetails/context';

export function CardControlActions() {
  const { expandAll, collapseAll, allExpanded, allCollapsed } = useApplicationCards();

  return (
    <Flex align="stretch" as="div" gap={5} justify="end">
      <Button emphasis="transparent" disabled={allExpanded} onClick={() => expandAll()}>
        Expand all
      </Button>
      <Button disabled={allCollapsed} onClick={() => collapseAll()} emphasis="transparent">
        Collapse all
      </Button>
    </Flex>
  );
}
