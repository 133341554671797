import { useMediaQuery } from '~/hooks/useMediaQuery';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';

export const ApplicationDetailsLayout = () => {
  const { isUltraWide, isWide, isNormal, isNarrow, isUltraNarrow, isMobile } = useMediaQuery();

  if (isUltraWide || isWide || isNormal) {
    return <DesktopLayout programDetailsLineSplit={false} />;
  }

  if (isNarrow || isUltraNarrow) {
    return <DesktopLayout programDetailsLineSplit={true} />;
  }

  if (isMobile) {
    return <MobileLayout />;
  }

  // should not reach here
  return null;
};
