import React from 'react';
import { useNotice } from '../useNotice';
import { HttpError } from '~/lib/useRequest';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { DocumentXOutlineIcon, ArrowRightOutlineIcon } from '@applyboard/ui-icons';
import { Dialog, Button, Flex, Text, TextInput, Spinner, Select, ButtonLink } from '@applyboard/crystal-ui';
import { useApplicationRejectionReason } from '~/pages/ApplicationDetails/data/useApplicationRejectionReasons';

import { useDeclineActivity } from './useDeclineActivity';
import { DialogHeader, STEPS } from './DialogHeading';

export function DeclineDialog() {
  const notice = useNotice();
  const activity = useDeclineActivity();
  const { apiError } = useErrorHandler();

  const [reason, setReason] = React.useState('');

  const [stepState, setStepState] = React.useState<number>(STEPS.STEP1);
  const [downloadUrl, setDownloadUrl] = React.useState('');
  const onClickStep1 = () => {
    activity
      .generateRejection(reason)
      .then((result) => {
        setDownloadUrl(result.letterPreviewDownloadUrl);
        setStepState(STEPS.STEP2);
      })
      .catch(() => apiError());
  };
  const onSubmit = () => {
    activity
      .declineApplicant(reason)
      .then(() => {
        notice.setNotice('Successfully declined Application.', 'positive');
        activity.completeProcess();
      })
      .catch((err) => {
        apiError(
          HttpError.of(err).message === 'invalid status'
            ? 'Application is already declined, please refresh the page'
            : undefined
        );
      });
  };

  return (
    <Dialog
      size="md"
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) activity.completeProcess();
      }}
    >
      <Dialog.Heading>
        <DialogHeader setStepState={setStepState} stepState={stepState} heading="Decline Applicant" />
      </Dialog.Heading>

      <Dialog.Content>
        {stepState === STEPS.STEP1 && (
          <Flex direction="column" gap={8}>
            <Text variant="bodyM" intent="secondary">
              Are you sure you want to decline this Application? This action cannot be reverted and will automatically
              close the application.
            </Text>
            <RejectionReason reason={reason} setReason={setReason} />
          </Flex>
        )}
        {stepState === STEPS.STEP2 && (
          <Flex direction="column" gap={8}>
            <ButtonLink
              aria-label="Download Rejection Letter"
              emphasis="highlighted"
              href={downloadUrl}
              intent="secondary"
              size="lg"
              leadIcon={DocumentXOutlineIcon}
              width="fill"
              mode="override_user_choice_and_open_in_new_tab"
            >
              Decline Letter
            </ButtonLink>
            <Text variant="bodyM" intent="secondary">
              A preview of the decline letter is available before sending it to the applicant. Once the letter is sent,
              you will not be able to make changes.
            </Text>
          </Flex>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton disabled={activity.isBusy} width="fill">
          Cancel
        </Dialog.CloseButton>
        {stepState === STEPS.STEP1 && (
          <Button
            intent="negative"
            onClick={onClickStep1}
            width="fill"
            disabled={!reason}
            loading={activity.isBusy}
            trailIcon={ArrowRightOutlineIcon}
          >
            Yes, proceed
          </Button>
        )}
        {stepState === STEPS.STEP2 && (
          <Button intent="negative" loading={activity.isBusy} onClick={onSubmit} width="fill">
            Send Decline
          </Button>
        )}
      </Dialog.Actions>
    </Dialog>
  );
}

function RejectionReason(props: { setReason: (reason: string) => void; reason: string }) {
  const { rejectionReasons, isFetchingRejectionReason, isRejectionReasonError } = useApplicationRejectionReason();
  const [showCustom, setShowCustom] = React.useState(false);
  if (isFetchingRejectionReason) {
    return (
      <Flex direction="column" align="center">
        <Flex>
          <Spinner />
        </Flex>
      </Flex>
    );
  }

  if (!isRejectionReasonError && rejectionReasons && rejectionReasons.length > 0) {
    return (
      <>
        <Select
          onChange={(reason: string) => {
            if (reason === 'Other') {
              props.setReason('');
              setShowCustom(true);
            } else {
              props.setReason(reason);
              setShowCustom(false);
            }
          }}
          value={showCustom ? 'Other' : props.reason}
          label="Reason for decision (optional)"
          appearance="styled"
          intent="secondary"
        >
          {rejectionReasons
            .map((r) => ({ id: r.id, value: r.code, label: r.name }))
            .concat([{ id: 'id', value: 'Other', label: 'Other' }])
            .map((r) => (
              <Select.Option key={r.id} value={r.value} label={r.label} />
            ))}
        </Select>

        {showCustom && (
          <TextInput
            label="Custom reason"
            intent="secondary"
            onChange={(reason: string) => props.setReason(reason)}
            value={props.reason}
          />
        )}
      </>
    );
  }
  return (
    <TextInput
      label="Reason for decision (optional)"
      intent="secondary"
      onChange={(reason: string) => props.setReason(reason)}
      value={props.reason}
    />
  );
}
