import React from 'react';
import styled from '@emotion/styled';
import { Accordion, Flex } from '@applyboard/crystal-ui';
import { IconType } from '../../utils';

export type ExpandableCardProps = {
  id: string;
  icon: IconType;
  title: React.ReactNode;
  children: React.ReactNode;
  isExpanded: boolean;
  hideChevron?: boolean;
  onIsExpandedChange: (isExpanded: boolean) => void;
};

export function ExpandableCard(props: ExpandableCardProps) {
  function onChange(value: string | string[]) {
    const expanded = Array.isArray(value) ? value.includes(props.id) : value == props.id;
    props.onIsExpandedChange(expanded);
  }

  return (
    <VisualBugFix>
      <Accordion onChange={onChange} value={props.isExpanded ? [props.id] : []}>
        <Accordion.Item
          hideChevron={props.hideChevron}
          autoOpen={false}
          id={props.id}
          icon={props.icon}
          title={props.title}
        >
          <Flex p={4} direction="column">
            {props.children}
          </Flex>
        </Accordion.Item>
      </Accordion>
    </VisualBugFix>
  );
}

const VisualBugFix = styled.div({
  // button
  '& > div > div > button': {
    padding: '20px',
  },
});
