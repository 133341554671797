import { PhoneOutlineIcon } from '@applyboard/ui-icons';
import { GridAlpha, Flex, Heading, Text } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

import { Card } from './Card';
import { LabelValue } from '~/components/uie-fix/LabelValue';

export function ContactInformation() {
  const {
    application: {
      applicant: { contactInformation, emergencyContact },
    },
  } = useAppProcessing();

  return (
    <Card id="contactInformation" icon={PhoneOutlineIcon} title="Contact Information">
      <Flex justify="start" direction="column">
        <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(2, 1fr)">
          <LabelValue label="PHONE NUMBER" value={contactInformation.phoneNumber} />
          <LabelValue label="ALTERNATIVE PHONE NUMBER" value={contactInformation.alternatePhoneNumber || '--'} />
          <LabelValue label="EMAIL" value={contactInformation.email} />
        </GridAlpha>

        <Flex direction="column" role="region" aria-describedby="Emergency Contact" gap={emergencyContact ? 8 : 1}>
          <Heading level={4} variant="titleS">
            Emergency Contact
          </Heading>
          {emergencyContact ? (
            <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(2, 1fr)">
              <LabelValue label="NAME" value={emergencyContact.name} />
              <LabelValue label="RELATIONSHIP" value={emergencyContact.relationship.name} />
              <GridAlpha.Item rowStart={2} rowEnd={2}>
                <LabelValue label="PHONE NUMBER" value={emergencyContact.phoneNumber} />
              </GridAlpha.Item>
              <GridAlpha.Item rowStart={3} rowEnd={3}>
                <LabelValue label="EMAIL" value={emergencyContact.email} />
              </GridAlpha.Item>
            </GridAlpha>
          ) : (
            <Text variant="bodyM" contrast="mid">
              Not provided
            </Text>
          )}
        </Flex>
      </Flex>
    </Card>
  );
}
