import React from 'react';
import { Button, Flex } from '@applyboard/crystal-ui';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';

export function AdditionalDocumentRequest() {
  const errorHandler = useErrorHandler();
  const [isBusy, setIsBusy] = React.useState(false);
  const { submit, disableFeature, documents } = useAdditionalDocumentRequest();

  async function onSubmit() {
    setIsBusy(true);
    await submit()
      .catch(() => errorHandler.apiError())
      .finally(() => setIsBusy(false));
  }

  return (
    <Flex gap={2} height="hug">
      <Button
        emphasis="highlighted"
        intent="secondary"
        size="md"
        width="fill"
        onClick={() => disableFeature()}
        disabled={isBusy}
      >
        Cancel
      </Button>
      <Button
        intent="primary"
        size="md"
        width="fill"
        onClick={onSubmit}
        loading={isBusy}
        disabled={documents.length === 0}
      >
        Send Request
      </Button>
    </Flex>
  );
}
