import { ErrorPage } from '~/pages/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { useEventTracking } from '~/hooks/useEventTracking';

export function ErrorBoundaryWrapper(props: { children: React.ReactNode }) {
  const { trackError } = useEventTracking('Error Boundary');
  const onError = (error: Error) => {
    trackError({ event: 'Error Boundary', error });
  };

  return (
    <ErrorBoundary fallbackRender={() => <ErrorPage errorCode={500} />} onError={onError}>
      {props.children}
    </ErrorBoundary>
  );
}
