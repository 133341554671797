import React, { useState } from 'react';
import { FilterOutlineIcon } from '@applyboard/ui-icons';
import { Button, Drawer, Flex } from '@applyboard/crystal-ui';

import { IntakeTermFilter, NationalityFilter, ProgramFilter, StatusFilter, SubmissionDateFilter } from '../components/';

import { useQueryParameter } from '../context';
import { useEventTracking } from '../hooks/useEventTracking';

export const FilterDrawer = () => {
  const { trackEvent } = useEventTracking('Filter Summary');
  const { resetQueryParameters, rnd, queryId } = useQueryParameter();
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const openChangeDialog = (open: boolean) => {
    if (open) {
      trackEvent({
        event: 'Clicked open filter drawer',
      });
    }
    setFilterDrawerOpen(open);
  };

  return (
    <Drawer open={isFilterDrawerOpen} placement="right" onOpenChange={openChangeDialog}>
      <Drawer.Trigger>
        <Button leadIcon={FilterOutlineIcon} type="button" intent="primary">
          Filter
        </Button>
      </Drawer.Trigger>
      <Drawer.Header>Filter your applications</Drawer.Header>
      <Flex direction="column" gap={4}>
        <NationalityFilter />
        <ProgramFilter />
        <IntakeTermFilter />
        <StatusFilter />
        <SubmissionDateFilter />
      </Flex>
      <Drawer.Footer>
        <Flex gap={2}>
          <Button
            intent="secondary"
            variant="highlighted"
            width="fill"
            onClick={() => {
              resetQueryParameters();
              trackEvent({ event: 'Clicked cancel in filters drawer' });
            }}
          >
            Clear all
          </Button>
          <Button
            intent="primary"
            width="fill"
            onClick={() => {
              setFilterDrawerOpen(false);
              trackEvent({
                event: 'Clicked apply in filters drawer',
                data: {
                  rnd,
                  queryId,
                },
              });
            }}
          >
            Apply
          </Button>
        </Flex>
      </Drawer.Footer>
    </Drawer>
  );
};
